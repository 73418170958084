import { Box, Stack, Tooltip, Typography } from '@mui/material';
import type { ControlViolation, InventoryResource } from '@repo/api-gw-sdk';
import { useRouter } from 'next/navigation';
import { Fragment } from 'react';

import OptionMenu from '@core/components/option-menu';

import {
  Accordion,
  AccordionDetails,
  AccordionDivider,
  AccordionSummary,
} from '@/components/accordion';
import {
  ControlIndicator,
  controlKeysPriorities,
} from '@/components/controls/controlIndicator';
import { ControlSummary } from '@/components/controls/controlSummary';
import { Loader } from '@/components/layout/loading';
import { Icon } from '@/components/shared/icon';
import { useRoles } from '@/contexts/useRoles';
import { useDAL } from '@/data/dal';
import { ControlsSeverity } from '@/data/inventory/data';

export const ViolationsDetails = ({
  resourceId,
  onEntityChange,
  entity,
}: {
  resourceId: string;
  onEntityChange?: () => Promise<void>;
  entity: InventoryResource;
}) => {
  const { isAuthorized, isAuthorizedResource } = useRoles();
  const dal = useDAL();
  const router = useRouter();
  const { body, mutate: mutateViolations } =
    dal.inventory.violations.list(resourceId);
  if (!body?.controlViolations) {
    return <Loader />;
  }

  const groupedResponse = body.controlViolations.reduce<
    Record<string, ControlViolation[]>
  >((acc, violation) => {
    const key = violation.muted
      ? 'muted'
      : violation.control.severity.toLowerCase();

    acc[key] = acc[key] || [];
    acc[key].push(violation);

    return acc;
  }, {});

  const toggleViolationMute = (violation: ControlViolation) => {
    void dal.inventory.violations
      .update(violation.resourceId, {
        id: violation.id,
        resourceId: violation.resourceId,
        muted: !violation.muted,
      })
      .then(() => {
        void mutateViolations();
        void onEntityChange?.();
      });
  };

  return (
    <Box className='mt-[12px]'>
      {controlKeysPriorities.map((level, levelIndex) => {
        if (!groupedResponse[level]) {
          return null;
        }

        const groupedControls =
          groupedResponse[level].reduce<Record<string, ControlViolation[]>>(
            (acc, violation) => {
              const priority = violation.control.severity.toLowerCase();
              acc[priority] = acc[priority] || [];
              acc[priority].push(violation);
              return acc;
            },
            {}
          ) || {};

        return (
          <Fragment key={level}>
            <Accordion defaultExpanded>
              <AccordionSummary data-testid='backup-status-content'>
                <Stack
                  className='w-full font-semibold'
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Box component={'span'}>
                    {`${(groupedResponse[level] || []).length} ${level === 'muted' ? ' muted violations' : `${level}-severity control violations`}`}
                  </Box>
                </Stack>
              </AccordionSummary>
              <AccordionDetails className='pr-0'>
                {(level === 'muted'
                  ? controlKeysPriorities.filter((x) => x !== 'muted')
                  : [level]
                ).map((internalLevel, index) => {
                  if (!groupedControls[internalLevel]?.length) {
                    return null;
                  }

                  return (
                    <Box key={internalLevel}>
                      {level === 'muted' && (
                        <Typography
                          variant='subtitle1'
                          className='uppercase'
                          sx={{
                            marginBottom: '16px',
                            marginTop: index === 0 ? '4px' : '24px',
                          }}
                        >{`${internalLevel}-severity controls`}</Typography>
                      )}
                      {groupedControls[internalLevel].map((violation) => {
                        return (
                          <Stack
                            key={violation.control.name}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            className='py-[6px]'
                          >
                            <Box>
                              <ControlIndicator
                                backgroundColor={ControlsSeverity[level].color}
                              />
                              <Box component={'span'} className='ml-[16px]'>
                                {violation.control.name}
                              </Box>
                            </Box>
                            <Stack direction={'row'} alignItems={'center'}>
                              <OptionMenu
                                data-testid='violation-control-options'
                                iconClassName='text-textPrimary'
                                icon='material-symbols-more-horiz'
                                options={[
                                  {
                                    text: level === 'muted' ? 'Unmute' : 'Mute',
                                    menuItemProps: {
                                      disabled: !isAuthorizedResource(
                                        'update:control_violations',
                                        entity
                                      ),
                                      onClick: () => {
                                        toggleViolationMute(violation);
                                      },
                                    },
                                  },
                                  {
                                    text: 'Control settings',
                                    menuItemProps: {
                                      disabled: !isAuthorized('read:controls'),
                                      onClick: () => {
                                        router.push('/settings/controls');
                                      },
                                    },
                                  },
                                ]}
                              />
                              <Tooltip
                                disableInteractive={false}
                                slotProps={{
                                  tooltip: {
                                    sx: {
                                      minWidth: '400px',
                                    },
                                  },
                                }}
                                title={
                                  <ControlSummary control={violation.control} />
                                }
                              >
                                <Icon iconClass='material-symbols-info-outline' />
                              </Tooltip>
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
            {levelIndex !== controlKeysPriorities.length - 2 ? (
              <AccordionDivider />
            ) : null}{' '}
          </Fragment>
        );
      })}
    </Box>
  );
};
