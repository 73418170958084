import { RefreshApi } from '@repo/api-gw-sdk';
import { useMemo } from 'react';

import { createConfig } from '@/contexts/api-config';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useUser } from '@/contexts/useUser';
import { getParsedJwt } from '@/libs/auth';

import { dalFactory } from './dalFactory';
import { createHttpClient } from './httpClient';

export function useDAL(projectId?: string) {
  const {
    clientApiBaseUrl: baseUrl,
    authToken,
    exposedTestingGlobals,
    updateAuthToken,
    isDev,
  } = useEnvironment();

  const { currentProjectId } = useUser(true);
  const proj = projectId ?? currentProjectId;

  const dal = useMemo(() => {
    const httpClient = createHttpClient(baseUrl, authToken);
    const configuration = createConfig(baseUrl, authToken, [
      {
        pre: async (context) => {
          if (!context.getUrl().endsWith('/refresh')) {
            const jwt = getParsedJwt(authToken);
            const exp = jwt?.exp;
            if (typeof exp === 'number') {
              const now = Math.floor(Date.now() / 1000);
              // if token expires in 2 minutes, refresh it
              if (exp < now + 120) {
                const response = await new RefreshApi(configuration).refresh();
                if (response.message) {
                  updateAuthToken(response.message);
                }

                return context;
              }
            }
          }

          return context;
        },
        post: async (context) => {
          if (context.httpStatusCode === 401) {
            // The user is not authenticated, delete the next jwt and redirect to login
            location.href = '/logout';
          }
          return context;
        },
      },
    ]);

    if (exposedTestingGlobals && typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).populateTestData = async () => {
        await httpClient.put(`/projects/${proj}/populate?version=1`, '{}');
        await httpClient.put(
          `/projects/${proj}/restore/populate-restore-jobs?version=1`,
          '{}'
        );
        await httpClient.put(
          `/projects/${proj}/metering/populate?version=1`,
          '{}'
        );
        await httpClient.put(
          `/projects/${proj}/inventory/populate-backup-jobs?version=1`,
          '{}'
        );
        await httpClient.put(
          `/projects/${proj}/visibility/populate?version=1`,
          '{}'
        );
        await httpClient.put(
          `/projects/${proj}/inventory/populate?version=1`,
          '{}'
        );
        await httpClient.put(
          `/projects/${proj}/search/populate?version=1`,
          '{}'
        );
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).cleanPopulatedTestData = async () => {
        await httpClient.delete(
          `/projects/${currentProjectId}/search/populate?version=1`
        );
      };
    }

    const dal = dalFactory(httpClient, configuration, proj); // TODO: Take care of case when currentProjectId is null
    if (isDev && typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).dal = dal;
    }
    return dal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, baseUrl, proj]);

  return dal;
}
