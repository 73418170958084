import {
  AccountCloudEnum,
  type Account,
  type FileSearchRecord,
} from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import type { RestoreGenericStorageProps } from './bucketSelection';
import { FileConfiguration } from './steps/fileConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreFileState extends RestoreGenericStorageProps {
  actionType: string;
  resourceIdForSnapshots?: string;
  snapshotId?: string;
  supportedCloudProviders: AccountCloudEnum[];
  restoreAccount?: Account;
  files: { path: string; isDirectory: boolean }[];
}

const restoreFileFlow: Step<RestoreFileState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'FileConfiguration',
      Component: FileConfiguration,
    },
  ],
};

interface Props {
  entity?: FileSearchRecord;
  path: string;
  snapshotId: string;
  resourceId: string;
}

export const RestoreFileWizard = (props: Props) => {
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;

  const close = () => {
    setIsOpen(false);

    return true;
  };

  const files = [{ path: props.path, isDirectory: false }];

  return (
    <Wizard<RestoreFileState>
      onFinish={close}
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      flow={[restoreFileFlow]}
      initialState={{
        files,
        actionType: 'file',
        snapshotId: props.snapshotId,
        resourceIdForSnapshots: props.resourceId,
        restoreMode: 'select',
        S3: {},
        StorageAccount: {},
        supportedCloudProviders: [AccountCloudEnum.Aws, AccountCloudEnum.Azure],
      }}
      title={'Restore File'}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
