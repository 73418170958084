import type {
  UpdateControlViolationInput,
  Configuration,
  Environment,
  DataClass,
  ApplicationOverride,
  InventoryResource,
} from '@repo/api-gw-sdk';
import {
  SnapshotsApi,
  ResourcesApi,
  ListInventoryApi,
  ListInventoryItemSnapshotsApi,
  ListInventoryAppsApi,
  ListInventorySourceRegionsApi,
  ListInventorySubnetsApi,
  ListInventoryNetworksApi,
  ListInventoryBackupRegionsApi,
  ListInventoryResourceTypesApi,
  ListInventoryEnvironmentsApi,
  ListControlViolationsApi,
  UpdateControlViolationApi,
  ListDataClassificationEntitiesApi,
  OverrideApplicationsApi,
  DeleteApplicationOverrideApi,
  CreateScanJobApi,
  ListInventoryResourceGroupsApi,
} from '@repo/api-gw-sdk';
import type { ColumnSort, PaginationState } from '@tanstack/react-table';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import type { Condition } from '@/types/advanceFilter';

import type { HttpClient } from './httpClient';

const baseUrl = '/inventory';

export const inventory = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const listInventoryApi = new ListInventoryApi(config);
  const resourcesApi = new ResourcesApi(config);
  const listInventoryItemSnapshotsApi = new ListInventoryItemSnapshotsApi(
    config
  );
  const listInventoryAppsApi = new ListInventoryAppsApi(config);
  const listInventorySubnetsApi = new ListInventorySubnetsApi(config);
  const listInventoryNetworksApi = new ListInventoryNetworksApi(config);
  const listInventoryEnvironmentsApi = new ListInventoryEnvironmentsApi(config);
  const listInventoryBackupRegionsApi = new ListInventoryBackupRegionsApi(
    config
  );
  const listDataClassificationEntitiesApi =
    new ListDataClassificationEntitiesApi(config);
  const listInventoryResourceTypesApi = new ListInventoryResourceTypesApi(
    config
  );
  const listInventorySourceRegionsApi = new ListInventorySourceRegionsApi(
    config
  );
  const listControlViolationsApi = new ListControlViolationsApi(config);
  const updateControlViolationApi = new UpdateControlViolationApi(config);

  const overrideApplicationsApi = new OverrideApplicationsApi(config);
  const deleteApplicationOverrideApi = new DeleteApplicationOverrideApi(config);

  const createScanJobApi = new CreateScanJobApi(config);
  const getSnapshotApi = new SnapshotsApi(config);
  const listInventoryResourceGroupsApi = new ListInventoryResourceGroupsApi(
    config
  );

  return {
    listAsync: (
      pagination: PaginationState,
      sorting: ColumnSort,
      columnFilters?: Condition
    ) =>
      listInventoryApi.listInventory(
        pagination.pageIndex,
        pagination.pageSize,
        `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
        currentProjectId,
        columnFilters ? toJsonText(columnFilters) : undefined
      ),
    list: (
      pagination: PaginationState,
      sorting: ColumnSort,
      columnFilters: Condition | undefined
    ) =>
      httpClient.execute(
        [baseUrl, pagination, sorting, columnFilters, currentProjectId],
        () =>
          listInventoryApi.listInventory(
            pagination.pageIndex,
            pagination.pageSize,
            `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`,
            currentProjectId,
            columnFilters ? toJsonText(columnFilters) : undefined
          )
      ),
    get: (id: string) =>
      httpClient.execute([baseUrl, id, currentProjectId], () =>
        resourcesApi.getResource(id, currentProjectId)
      ),
    getMultiple: (ids: string[]) =>
      httpClient.execute([baseUrl, ids, currentProjectId], () =>
        Promise.all(
          ids.map((id) => resourcesApi.getResource(id, currentProjectId))
        )
      ),
    snapshots: {
      list: (
        resourceId: string,
        type: 'eon' | 'gp',
        pageIndex: number,
        pageSize: number
      ) =>
        httpClient.execute(
          [
            baseUrl,
            'snapshots',
            resourceId,
            type,
            pageIndex,
            pageSize,
            currentProjectId,
          ],
          () =>
            listInventoryItemSnapshotsApi.listInventoryItemSnapshots(
              resourceId,
              pageIndex,
              pageSize,
              type,
              currentProjectId
            )
        ),
      get: (snapshotId: string) =>
        httpClient.execute(
          [baseUrl, 'snapshot', snapshotId, currentProjectId],
          () => getSnapshotApi.getSnapshot(snapshotId, currentProjectId)
        ),
    },
    apps: {
      list: () =>
        httpClient.execute([baseUrl, '/apps', currentProjectId], () =>
          listInventoryAppsApi.listInventoryApps(currentProjectId)
        ),
      override: (resourceIds: string[], apps: string[]) => {
        return overrideApplicationsApi.overrideApplications(currentProjectId, {
          resourceIds,
          applications: apps,
        });
      },
      async deleteOverride(resources: InventoryResource[]) {
        const payload = resources.flatMap((res) => {
          return (res.classifications?.appsDetails?.apps || []).map((app) => {
            return {
              resourceId: res.id,
              name: app.name,
            } satisfies ApplicationOverride;
          });
        }) satisfies ApplicationOverride[];

        return deleteApplicationOverrideApi.deleteApplicationOverride(
          currentProjectId,
          payload
        );
      },
    },
    regions: {
      list: () =>
        httpClient.execute([baseUrl, '/source-regions', currentProjectId], () =>
          listInventorySourceRegionsApi.listInventorySourceRegions(
            currentProjectId
          )
        ),
    },
    subnets: {
      list: () =>
        httpClient.execute([baseUrl, '/subnets', currentProjectId], () =>
          listInventorySubnetsApi.listInventorySubnets(currentProjectId)
        ),
    },
    resourceGroups: {
      list: () =>
        httpClient.execute(
          [baseUrl, '/resource-groups', currentProjectId],
          () =>
            listInventoryResourceGroupsApi.listInventoryResourceGroups(
              currentProjectId
            )
        ),
    },
    networks: {
      list: () =>
        httpClient.execute([baseUrl, '/networks', currentProjectId], () =>
          listInventoryNetworksApi.listInventoryNetworks(currentProjectId)
        ),
    },
    resourceTypes: {
      list: () =>
        httpClient.execute([baseUrl, '/resource-types', currentProjectId], () =>
          listInventoryResourceTypesApi.listInventoryResourceTypes(
            currentProjectId
          )
        ),
    },
    environments: {
      list: () =>
        httpClient.execute([baseUrl, '/environments', currentProjectId], () =>
          listInventoryEnvironmentsApi.listInventoryEnvironments(
            currentProjectId
          )
        ),
      override: (resourceId: string, environment: Environment) => {
        return resourcesApi.overrideEnvironment(currentProjectId, resourceId, {
          environment,
        });
      },
      deleteOverride(resourceId: string) {
        return resourcesApi.removeEnvironmentOverride(
          currentProjectId,
          resourceId
        );
      },
    },
    dataClasses: {
      list: (resourceId: string) =>
        httpClient.execute(
          [baseUrl, 'classifications', resourceId, currentProjectId],
          () =>
            listDataClassificationEntitiesApi.listDataClassificationEntities(
              currentProjectId,
              resourceId
            )
        ),
      override: (resourceId: string, payload: DataClass[]) => {
        return resourcesApi.overrideDataClasses(currentProjectId, resourceId, {
          dataClasses: payload,
        });
      },
      deleteOverride: (resourceId: string) => {
        return resourcesApi.removeDataClassesOverride(
          currentProjectId,
          resourceId
        );
      },
    },
    backupRegions: {
      list: () =>
        httpClient.execute([baseUrl, '/backup-regions', currentProjectId], () =>
          listInventoryBackupRegionsApi.listInventoryBackupRegions(
            currentProjectId
          )
        ),
    },
    violations: {
      list: (id: string) =>
        httpClient.execute([baseUrl, id, '/violations', currentProjectId], () =>
          listControlViolationsApi.listControlViolations(id, currentProjectId)
        ),
      update: (id: string, payload: UpdateControlViolationInput) =>
        updateControlViolationApi.updateControlViolation(
          id,
          currentProjectId,
          payload
        ),
    },
    resources: {
      excludeResourceFromBackup: (resourceId: string) =>
        resourcesApi.excludeResourceFromBackup(currentProjectId, resourceId),
      cancelResourceBackupExclusion: (resourceId: string) =>
        resourcesApi.cancelResourceBackupExclusion(
          currentProjectId,
          resourceId
        ),
    },
    jobs: {
      createScanJob: (resourceId: string) => {
        return createScanJobApi.createScanJob(currentProjectId, { resourceId });
      },
    },
  };
};
