import {
  apps,
  accountId,
  backupPolicies,
  backupRegions,
  backupStorageSize,
  dataClasses,
  environment,
  lastBackup,
  resourceId,
  sourceRegion,
  sourceStorageSize,
  subnets,
  tags,
  vpc,
  resourceName,
  resourceType,
  vaults,
  genericSnapshotsCount,
  dbEngine,
  dateDiscovered,
  resourceGroupName,
} from '@/components/queryBuilder/properties';

export const inventoryItemFields = [
  resourceName,
  resourceId,
  resourceGroupName,
  dbEngine,
  resourceType,
  dataClasses,
  environment,
  apps,
  tags,

  backupPolicies,
  vaults,
  lastBackup,
  backupRegions,
  sourceStorageSize,
  backupStorageSize,
  genericSnapshotsCount,
  dateDiscovered,

  accountId,
  sourceRegion,
  vpc,
  subnets,
];
