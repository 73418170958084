import { Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { NotificationPolicyTypeEnum } from '@repo/api-gw-sdk';

import type { StepProps } from '@/components/wizard/StepProps';

import type { UpsertNotificationPolicyState } from './upsertNotificationPolicyFlow';

export const NotificationPolicyCredentials = (
  props: StepProps<UpsertNotificationPolicyState>
) => {
  const next = () => {
    props.setNextStep(props.currentStep.next?.[0]);
  };

  const validateParams = () => {
    if (props.wizardState.type === NotificationPolicyTypeEnum.Email) {
      return !!props.wizardState.config?.email?.email;
    }

    if (props.wizardState.type === NotificationPolicyTypeEnum.Sns) {
      return !!props.wizardState.config?.sns?.topicArn;
    }

    if (props.wizardState.type === NotificationPolicyTypeEnum.Slack) {
      return (
        !!props.wizardState.config?.slack?.channel &&
        !!props.wizardState.config?.slack?.token
      );
    }

    return false;
  };

  return (
    <Stack
      sx={{ height: '100%' }}
      alignItems='center'
      justifyContent='center'
      gap='40px'
    >
      <Typography variant='h3' sx={{ textAlign: 'center' }}>
        Notification policy parameters
      </Typography>

      {props.wizardState.type === NotificationPolicyTypeEnum.Email && (
        <Stack direction='row' alignItems='center' gap='24px'>
          <Typography className='font-bold w-[70px]'>Email</Typography>
          <Divider orientation='vertical' flexItem />
          <TextField
            className='w-[582px]'
            value={props.wizardState.config?.email?.email || ''}
            onChange={(event) =>
              props.setWizardState((state) => ({
                ...state,
                config: {
                  email: {
                    email: event.target.value,
                  },
                },
              }))
            }
          />
        </Stack>
      )}
      {props.wizardState.type === NotificationPolicyTypeEnum.Sns && (
        <Stack direction='row' alignItems='center' gap='24px'>
          <Typography className='font-bold w-[70px]'>Topic Arn</Typography>
          <Divider orientation='vertical' flexItem />
          <TextField
            className='w-[582px]'
            value={props.wizardState.config?.sns?.topicArn || ''}
            onChange={(event) =>
              props.setWizardState((state) => ({
                ...state,
                config: {
                  sns: {
                    topicArn: event.target.value,
                  },
                },
              }))
            }
          />
        </Stack>
      )}
      {props.wizardState.type === NotificationPolicyTypeEnum.Slack && (
        <>
          <Stack direction='row' alignItems='center' gap='24px'>
            <Typography className='font-bold w-[70px]'>Channel ID</Typography>
            <Divider orientation='vertical' flexItem />
            <TextField
              className='w-[582px]'
              value={props.wizardState.config?.slack?.channel || ''}
              onChange={(event) =>
                props.setWizardState((state) => ({
                  ...state,
                  config: {
                    slack: {
                      token: state.config?.slack?.token,
                      channel: event.target.value,
                    },
                  },
                }))
              }
            />
          </Stack>
          <Stack direction='row' alignItems='center' gap='24px'>
            <Typography className='font-bold w-[70px]'>
              Bot user OAuth token
            </Typography>
            <Divider orientation='vertical' flexItem />
            <TextField
              className='w-[582px]'
              value={props.wizardState.config?.slack?.token || ''}
              onChange={(event) =>
                props.setWizardState((state) => ({
                  ...state,
                  config: {
                    slack: {
                      channel: state.config?.slack?.channel,
                      token: event.target.value,
                    },
                  },
                }))
              }
            />
          </Stack>
        </>
      )}
      <Button
        sx={{ width: '200px' }}
        variant='contained'
        disabled={!validateParams()}
        onClick={next}
      >
        Next
        <i className='material-symbols-arrow-right-alt ml-[8px]' />
      </Button>
    </Stack>
  );
};
