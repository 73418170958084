import {
  Box,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import {
  EncryptionComponent,
  getEncryptionKey,
} from '@/components/encryption/encryptionComponent';
import { SidePanelPropsTable } from '@/components/panels/instance/tabs/shared';
import { RDSSubnetGroupSelect } from '@/components/regions/rdsSubnetGroupSelect';
import { RegionSelect } from '@/components/regions/regionSelect';
import { SecurityGroupSelect } from '@/components/regions/securityGroupSelect';
import { CircleImage } from '@/components/shared/circleImage';
import { TagsSection } from '@/components/tags/tagsSection';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { ApplicationsLogosWithAliases } from '@/data/inventory/data';

import { EonTagRemark } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreRDSState } from '../restoreRDSWizard';

export const RDSConfiguration = (props: StepProps<RestoreRDSState>) => {
  const dal = useDAL();
  const { body: inventoryResourceBody } = dal.inventory.get(
    props.wizardState.resourceId
  );

  useEffect(() => {
    props.setWizardState((state) => ({
      ...state,
      securityGroupId: undefined,
      vpc: undefined,
      rdsSubnetGroupName: undefined,
      regionName: state.initialRegionName,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wizardState.restoreAccount]);

  const canGoNext = () => {
    if (
      !props.wizardState.regionName ||
      !props.wizardState.resourceName ||
      !props.wizardState.securityGroupId ||
      !props.wizardState.rdsSubnetGroupName ||
      !getEncryptionKey(props.wizardState.encryption)
    ) {
      return false;
    }

    return true;
  };

  const onNextClick = () => {
    const tags = props.wizardState.tags || {};
    tags['eon:restore'] = 'true';

    void dal.restore
      .database(props.wizardState.resourceId, props.wizardState.snapshotId, {
        tags,
        restoreAccountId: props.wizardState.restoreAccount!.id,
        encryptionKeyId: getEncryptionKey(props.wizardState.encryption),
        restoredName: props.wizardState.resourceName,
        restoreRegion: props.wizardState.regionName,
        securityGroup: props.wizardState.securityGroupId,
        subnetGroup: props.wizardState.rdsSubnetGroupName,
      })
      .then(() => {
        props.abort();
        props.onSuccess('Restore job has started!');
      });
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Restore'
      onBackClick={props.back}
      canGoNext={canGoNext}
      onNextClick={onNextClick}
    >
      <Box className='mx-[40px] my-[24px]'>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <FormControl size='small' className='flex-1'>
            <FormLabel>Database name*</FormLabel>
            <TextField
              size='small'
              value={props.wizardState.resourceName || ''}
              onChange={(event) =>
                props.setWizardState((state) => ({
                  ...state,
                  resourceName: event.target.value,
                }))
              }
            />
          </FormControl>
          <RegionSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={props.wizardState.regionName}
            onChange={(region) =>
              props.setWizardState((state) => ({
                ...state,
                regionName: region,
                rdsSubnetGroupName: undefined,
                securityGroupId: undefined,
                vpc: undefined,
              }))
            }
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <RDSSubnetGroupSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={props.wizardState.regionName}
            rdsSubnetGroupName={props.wizardState.rdsSubnetGroupName}
            onChange={(rdsSubnetGroupName, vpc) =>
              props.setWizardState((state) => ({
                ...state,
                rdsSubnetGroupName,
                vpc,
                securityGroupId: undefined,
              }))
            }
          />
          <SecurityGroupSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={props.wizardState.regionName}
            securityGroupId={props.wizardState.securityGroupId}
            vpc={props.wizardState.vpc}
            isVpcMandatory={true}
            isSubnetIdMandatory={false}
            isMandatoryField={true}
            onChange={(securityGroupId) =>
              props.setWizardState((state) => ({
                ...state,
                securityGroupId,
              }))
            }
          />
        </Stack>
      </Box>
      <Divider />
      {inventoryResourceBody?.resource.databaseProperties?.engine && (
        <>
          <Box className='mx-[40px] my-[24px]'>
            <Typography variant='h6'>Database settings</Typography>
            <SidePanelPropsTable className='my-[24px]'>
              <tbody>
                <tr>
                  <td className='props-col'>Engine</td>
                  <td className='capitalize'>
                    <div className='flex items-center'>
                      <CircleImage
                        alt={
                          inventoryResourceBody.resource.databaseProperties
                            .engine
                        }
                        src={
                          ApplicationsLogosWithAliases[
                            inventoryResourceBody.resource.classifications
                              ?.appsDetails?.apps?.[0]?.name || ''
                          ]
                        }
                        className='mr-[8px]'
                      />
                      <Typography color='var(--mui-palette-text-secondary)'>
                        {
                          inventoryResourceBody.resource.databaseProperties
                            .engine
                        }
                      </Typography>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='props-col'>Engine version</td>
                  <td>
                    {
                      inventoryResourceBody.resource.databaseProperties
                        .engineVersion
                    }
                  </td>
                </tr>
              </tbody>
            </SidePanelPropsTable>
          </Box>
          <Divider />
        </>
      )}
      <CardContent className='px-0'>
        {props.wizardState.regionName && (
          <EncryptionComponent
            encryption={props.wizardState.encryption}
            accountId={props.wizardState.restoreAccount!.id}
            regionName={props.wizardState.regionName}
            onChange={(encryption) =>
              props.setWizardState((state) => ({
                ...state,
                encryption,
              }))
            }
          />
        )}
      </CardContent>
      <Divider />
      <CardContent className='px-0'>
        <TagsSection
          initialTags={{}}
          tags={props.wizardState.tags || {}}
          keepOriginalTags={props.wizardState.keepOriginalTags}
          setKeepOriginalTags={(value: boolean) =>
            props.setWizardState((state) => ({
              ...state,
              keepOriginalTags: value,
            }))
          }
          onChange={(tags) =>
            props.setWizardState((state) => ({ ...state, tags }))
          }
        />
      </CardContent>
    </StepContainer>
  );
};
