/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { NotificationArgumentsRestoreJobFailed } from '../models/NotificationArgumentsRestoreJobFailed';
import { NotificationArgumentsRestoreJobSucceeded } from '../models/NotificationArgumentsRestoreJobSucceeded';

export class NotificationArguments {
  'restoreJobFailed'?: NotificationArgumentsRestoreJobFailed;
  'restoreJobSucceeded'?: NotificationArgumentsRestoreJobSucceeded;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreJobFailed",
      "baseName": "restoreJobFailed",
      "type": "NotificationArgumentsRestoreJobFailed",
      "format": ""
    },
    {
      "name": "restoreJobSucceeded",
      "baseName": "restoreJobSucceeded",
      "type": "NotificationArgumentsRestoreJobSucceeded",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return NotificationArguments.attributeTypeMap;
  }

  public constructor() {
  }
}

