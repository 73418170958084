import { useEffect, useState } from 'react';

import { VaultColors } from '@/components/vaults/EditBackupVaultPanel';
import { stringToHash } from '@/utils/string';

import type { BackupVault } from './backupVault';

import { useDAL } from '../dal';
import { PreferencesKey } from '../dal/preferences';

function useBackupVaults(): {
  data: BackupVault[];
  pagination?: { recordsCount: number };
  loading: boolean;
  mutate: () => void;
} {
  const [data, setData] = useState<BackupVault[]>([]);
  const dal = useDAL();
  const {
    isLoading: vaultsLoading,
    body: vaultsData,
    mutate,
  } = dal.vaults.list();
  const {
    body: pref,
    isLoading: prefLoading,
    mutate: mutatePref,
  } = dal.preferences.getAccountPref(PreferencesKey.BackupVault);

  const reload = () => {
    void mutate().then(() => {
      void mutatePref();
    });
  };

  useEffect(() => {
    if (!vaultsLoading && !prefLoading) {
      if (vaultsData?.backupVaults) {
        const resData = vaultsData?.backupVaults?.map((v) => {
          const userSetting = pref?.value?.colors?.find((p) => p.id === v.id);

          return {
            ...v,
            backgroundColor: userSetting?.color || getDeterministicColor(v.id),
          };
        });

        setData(resData || []);
      }
    }
  }, [prefLoading, vaultsLoading, vaultsData, pref]);

  return {
    data,
    pagination: vaultsData?.pagination,
    loading: vaultsLoading || prefLoading,
    mutate: reload,
  };
}

export default useBackupVaults;

function getDeterministicColor(id = 'yo'): string {
  return VaultColors[stringToHash(id, VaultColors.length)];
}
