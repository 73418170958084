import type {
  Account,
  SnapshotProperties,
  SnapshotPropertiesVolumePropertiesInner,
} from '@repo/api-gw-sdk';
import { AccountCloudEnum } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import type { VolumeConfigState } from './restoreEc2InstanceWizard';
import { ConvertToAMIVolumeConfiguration } from './steps/convertAMIVolumeConfiguration';
import { ImageConfiguration } from './steps/imageConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { Encryption } from '../encryption/encryptionComponent';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface ConvertAMIBaseState {
  regionName?: string;
  tags?: Record<string, string>;
  keepOriginalTags?: boolean;
  volumesConfigState?: VolumeConfigState[];
}

export interface ConvertToAMIState {
  name?: string;
  description?: string;
  resourceId: string;
  snapshotId: string;
  supportedCloudProviders: AccountCloudEnum[];
  restoreAccount?: Account;
  sharedEncryption?: Encryption;
  crossStepsState: ConvertAMIBaseState;
  initialState: ConvertAMIBaseState;
  volumes: SnapshotPropertiesVolumePropertiesInner[];
}

const convertToAMIFlow: Step<ConvertToAMIState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'ImageConfiguration',
      Component: ImageConfiguration,
      next: [
        {
          name: 'ConvertToAMIVolumeConfiguration',
          Component: ConvertToAMIVolumeConfiguration,
        },
      ],
    },
  ],
};

export const ConvertToAMIWizard = ({
  resourceId,
  snapshotId,
  sourceRegion,
  volumes,
  resourceProperties,
}: {
  resourceId: string;
  snapshotId: string;
  sourceRegion?: string;
  volumes: SnapshotPropertiesVolumePropertiesInner[];
  resourceProperties?: SnapshotProperties;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: { id: resourceId, onClose: () => setIsOpen(false) },
    });

    return true;
  };

  const tags = resourceProperties?.tags || {};
  const volumesSettings: VolumeConfigState[] = volumes.map((vol) => ({
    ...vol,
    isEncrypted: true,
    encryptionKeyId: undefined,
    keepOriginalSettings: true,
    keepOriginalTags: true,
    tags: vol.tags || {},
  }));

  return (
    <Wizard<ConvertToAMIState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[convertToAMIFlow]}
      initialState={{
        snapshotId,
        resourceId,
        supportedCloudProviders: [AccountCloudEnum.Aws],
        volumes,
        sharedEncryption: {
          isEncrypted: true,
          encryptionKeyId: undefined,
          arn: undefined,
          mode: 'select',
        },
        crossStepsState: {
          volumesConfigState: volumesSettings,
        },
        initialState: {
          regionName: sourceRegion || 'us-east-1',
          tags,
          keepOriginalTags: true,
          volumesConfigState: volumesSettings,
        },
      }}
      title={`Convert to AMI`}
      stepperLabels={[
        'Restore account',
        'Image configuration',
        'Volume configuration',
      ]}
    />
  );
};
