import {
  SearchInputCloudProviderEnum,
  type SearchFilesResponse,
  type Configuration,
} from '@repo/api-gw-sdk';
import { QueryDBApi, SearchApi } from '@repo/api-gw-sdk';

import { toJsonText } from '@/components/queryBuilder/QueryLanguageConverter';
import type { Condition } from '@/types/advanceFilter';

import type { HttpClient } from './httpClient';

const baseUrl = '/search';

export const search = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const searchApi = new SearchApi(config);
  const queryDbApi = new QueryDBApi(config);

  return {
    files: {
      execute: (
        term: string,
        limit: number,
        cloudProvider: SearchInputCloudProviderEnum,
        queryExecutionId?: string,
        pageToken?: string,
        filters?: Condition,
        startDate?: Date,
        endDate?: Date
      ) =>
        httpClient.execute(
          [
            baseUrl,
            'files',
            currentProjectId,
            term,
            limit,
            cloudProvider,
            queryExecutionId,
            pageToken,
            filters,
            startDate,
            endDate,
          ],
          () =>
            searchApi
              .search(currentProjectId, {
                cloudProvider,
                query: term,
                limit,
                pageToken,
                queryExecutionId,
                filters: filters ? toJsonText(filters) : undefined,
                startDate,
                endDate,
              })
              .then(
                (response) => ({
                  ...response,
                  data: response.data.map((file) => ({
                    ...file,
                    id: file.resourceId + file.path,
                  })),
                }),
                (error) => {
                  if (error.code === 404) {
                    // nothing - no results found, not an error
                    return {
                      data: [],
                      pagination: {
                        recordsCount: 0,
                        nextToken: undefined,
                        queryExecutionId: undefined,
                      },
                    } satisfies SearchFilesResponse;
                  }
                  throw error;
                }
              )
        ),
    },
    dbs: {
      execute: (term: string, pageIndex: number, pageSize: number) =>
        httpClient.execute(
          [baseUrl, 'db', currentProjectId, term, pageIndex, pageSize],
          () =>
            queryDbApi.queryDB(
              currentProjectId,
              {
                cloudProvider: SearchInputCloudProviderEnum.Aws,
                query: term,
              },
              pageIndex,
              pageSize
            )
        ),
    },
  };
};
