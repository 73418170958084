import { Button, OutlinedInput, Stack, Typography } from '@mui/material';

import type { StepProps } from '@/components/wizard/StepProps';

import type { UpsertApiCredentialsState } from './upsertApiCredentialsFlow';

const validateName = (name = ''): boolean => {
  return name.length >= 4;
};

export const ApiCredentialsNameSelection = (
  props: StepProps<UpsertApiCredentialsState>
) => {
  const next = () => {
    props.setNextStep(props.currentStep.next?.[0]);
  };

  return (
    <Stack
      sx={{ height: '100%' }}
      alignItems='center'
      justifyContent='center'
      gap='24px'
    >
      <Typography variant='h3' sx={{ textAlign: 'center' }}>
        Credentials name
      </Typography>
      <OutlinedInput
        autoFocus
        sx={{
          width: '655px',
          padding: '8px',
        }}
        placeholder='Example: Dashboard reports'
        onChange={(e) =>
          props.setWizardState((state) => ({
            ...state,
            givenName: e.target.value,
          }))
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter' && validateName(props.wizardState.givenName)) {
            next();
          }
        }}
        value={props.wizardState.givenName}
        endAdornment={
          <Button
            sx={{ width: '200px' }}
            variant='contained'
            disabled={!validateName(props.wizardState.givenName)}
            onClick={next}
          >
            Next
            <i className='material-symbols-arrow-right-alt ml-[8px]' />
          </Button>
        }
      />
      <Typography
        variant='subtitle1'
        sx={{ textAlign: 'center' }}
        className='max-w-[480px]'
      >
        Pick a name that tells you what you’ll use the credentials for, so
        you’ll always know what tools are accessing your account.
      </Typography>
    </Stack>
  );
};
