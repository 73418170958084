import {
  Box,
  capitalize,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  type SelectProps,
} from '@mui/material';
import {
  type AuditLog,
  type BackupPolicy,
  type FileSearchRecord,
  type InventoryResource,
  type Job,
  type Notification,
  type User,
  ResourceType,
} from '@repo/api-gw-sdk';
import { BackupStatus, Provider, DataClass } from '@repo/api-gw-sdk';
import type { Row } from '@tanstack/react-table';
import { type StaticImageData } from 'next/image';
import React from 'react';

import { identifyDelimiter } from '@/app/(dashboard)/search/searchUtils';
import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';
import {
  ApplicationsLogos,
  ApplicationsLogosWithAliases,
  BackupStatuses,
  CloudProviders,
  DataClasses,
  Environments,
  ResourceTypes,
} from '@/data/inventory/data';
import { formatRegionName } from '@/data/inventory/regions';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import {
  PropertyPolicyRelation,
  PropertyType,
  StringOperator,
  type FilterProperty,
} from '@/types/advanceFilter';
import type { SamlGroup } from '@/types/groups';
import { isDateValid } from '@/utils/dateTime';
import { dayjs } from '@/utils/dayjs';
import { fileSizeFormatter } from '@/utils/fileSizeFormatter';

import {
  AppsFilter,
  BackupStatusFilter,
  FreeTextFilter,
  MultiSelectFilter,
  TagsFilter,
} from './filters';

import BackupPolicyTag, {
  BackupPolicyTagFromId,
} from '../backupPolicy/BackupPolicyTag';
import { BackupStatusIcon } from '../backupStatus/backupStatusIcon';
import { ControlsColumnIndicators } from '../controls/controlIndicator';
import type { FileExplorerTableItem } from '../explorer/fileExplorerPage';
import { NotificationStatuses, NotificationTypes } from '../notifications/data';
import { CircleImage } from '../shared/circleImage';
import { Tag } from '../tag';
import { TagsElasticContainer } from '../tags/tagsElasticContainer';
import VaultTag from '../vaults/VaultTag';

const PropertySelection = (
  props: SelectProps & {
    showImagesInRender?: boolean;
    allowAddingOption?: boolean;
    options: {
      value: string;
      label: React.ReactNode;
      image?: StaticImageData;
    }[];
  }
) => {
  const { options, showImagesInRender, allowAddingOption, ...rest } = props;

  return (
    <Select
      {...rest}
      displayEmpty={true}
      renderValue={(selected) => {
        const opts = (Array.isArray(selected) ? selected : [selected])
          .map((opt) => options.find((o) => o.value === opt)!)
          .filter(Boolean);

        if (opts.length === 0) {
          return (
            <Typography
              sx={{ alignSelf: 'center' }}
              color='var(--mui-palette-text-disabled)'
            >
              Select
            </Typography>
          );
        }

        return opts.map((option, i) =>
          showImagesInRender && option.image ? (
            <Tooltip key={option.value} title={option.label as string}>
              <>
                <CircleImage
                  className='mr-[8px]'
                  style={{ display: 'inline' }}
                  alt={option.value}
                  src={option.image}
                  height={30}
                />
              </>
            </Tooltip>
          ) : (
            <React.Fragment key={option.value}>
              {option.label}
              {i < opts.length - 1 ? ', ' : null}
            </React.Fragment>
          )
        );
      }}
    >
      {options.map((option) => {
        const isChecked = (props.value as string[]).includes(option.value);
        return (
          <MenuItem key={option.value} value={option.value}>
            {props.multiple && (
              <Checkbox className='mr-[8px]' checked={isChecked} />
            )}
            {option.image && (
              <>
                <CircleImage
                  contrast={isChecked}
                  alt={option.value}
                  src={option.image}
                  className='mr-[8px]'
                  height={30}
                />
              </>
            )}
            <ListItemText primary={option.label} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const id: FilterProperty<InventoryResource> = {
  name: 'id',
  displayName: '',
  type: PropertyType.String,
  group: 'primary',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
};

export const cloudProvider: FilterProperty<InventoryResource> = {
  name: 'cloudProvider',
  displayName: 'Cloud Provider',
  type: PropertyType.String,
  group: 'primary',
  column: {
    enableHiding: true,
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) =>
      row.original.cloudProvider && (
        <Typography>{row.original.cloudProvider}</Typography>
      ),
  },
  field: {
    value: (entity) => {
      if (!('cloudProvider' in entity)) {
        return undefined;
      }
      return <Typography>{entity.cloudProvider}</Typography>;
    },
  },
};

export const instanceId: FilterProperty<FileSearchRecord> = {
  name: 'instanceId',
  displayName: 'Resource ID',
  type: PropertyType.String,
  group: 'primary',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }) =>
      row.original.instanceId && (
        <Typography>{row.original.instanceId}</Typography>
      ),
  },
  field: {
    value: (entity) => {
      if (!('instanceId' in entity)) {
        return undefined;
      }
      return <Typography>{entity.instanceId}</Typography>;
    },
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by ID'} />
    ),
  },
  textInput: {
    Component: (props) => <TextField {...props} />,
  },
};

export const resourceId: FilterProperty<InventoryResource | FileSearchRecord> =
  {
    name: 'resourceId',
    displayName: 'Resource ID',
    type: PropertyType.String,
    group: 'primary',
    policyRelation: PropertyPolicyRelation.SingleValue,
    column: {
      cell: ({ row }) => {
        if ('providerResourceId' in row.original) {
          return (
            row.original.providerResourceId && (
              <Typography>{row.original.providerResourceId}</Typography>
            )
          );
        }

        return (
          row.original.resourceId && (
            <Typography>{row.original.resourceId}</Typography>
          )
        );
      },
    },
    field: {
      value: (entity) => {
        if ('type' in entity) {
          return undefined;
        }

        if (entity.cloudProvider === Provider.Azure) {
          return entity.resourceName;
        }

        return (
          entity.providerResourceId && (
            <Typography>{entity.providerResourceId}</Typography>
          )
        );
      },
    },
    filter: {
      Component: (props) => (
        <FreeTextFilter {...props} placeholder={'Search by ID'} />
      ),
    },
    textInput: {
      Component: (props) => <TextField {...props} />,
    },
  };

const AccountIdCloudProviderMapping: Record<string, string> = {
  Azure: 'Subscription ID',
  GCP: 'Project ID',
};

export const accountId: FilterProperty<InventoryResource> = {
  name: 'accountId',
  displayName: 'Account ID',
  type: PropertyType.SingleValue,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }) =>
      row.original.accountId && (
        <Typography>{row.original.accountId}</Typography>
      ),
  },
  field: {
    titleKey: (entity) =>
      AccountIdCloudProviderMapping[entity.cloudProvider] || 'Account ID',
    value: (entity) =>
      entity.accountId && <Typography>{entity.accountId}</Typography>,
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.cloudAccounts.source.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.accounts || []).map((a) => ({
            id: a.providerAccountId,
            name: a.providerAccountId,
          }))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.cloudAccounts.source.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.accounts.map(({ providerAccountId }) => ({
              value: providerAccountId,
              label: providerAccountId,
            })) || []
          }
        />
      );
    },
  },
};

export const resourceName: FilterProperty<InventoryResource> = {
  name: 'resourceName',
  displayName: 'Name',
  type: PropertyType.String,
  group: 'primary',
  policyRelation: PropertyPolicyRelation.String,
  column: {
    enableHiding: false,
    cell: ({ row }) =>
      row.original.resourceName && (
        <Typography>{row.original.resourceName}</Typography>
      ),
  },
  field: {
    value: (entity) =>
      entity.resourceName && <Typography>{entity.resourceName}</Typography>,
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by name'} />
    ),
  },
  textInput: {
    Component: (props) => <TextField {...props} />,
  },
};

export const dateDiscovered: FilterProperty<InventoryResource> = {
  name: 'dateDiscovered',
  displayName: 'First discovered',
  type: PropertyType.Date,
  group: 'backup-details',
  column: {
    cell: ({ row }) =>
      isDateValid(row.original.dateDiscoveredTime) ? (
        <Typography>
          {dayjs(row.original.dateDiscoveredTime).format('ll HH:mm:ss')}
        </Typography>
      ) : undefined,
  },
  field: {
    value: (entity) =>
      isDateValid(entity.dateDiscoveredTime) ? (
        <Typography>
          {dayjs(entity.dateDiscoveredTime).format('ll HH:mm:ss')}
        </Typography>
      ) : undefined,
  },
};

export const createdTime: FilterProperty<InventoryResource> = {
  name: 'createdTime',
  displayName: 'Created',
  type: PropertyType.Date,
  group: 'source-location',
  column: {
    cell: ({ row }) =>
      isDateValid(row.original.createdTime) ? (
        <Typography>
          {dayjs(row.original.createdTime).format('ll HH:mm:ss')}
        </Typography>
      ) : undefined,
  },
  field: {
    value: (entity) =>
      isDateValid(entity.createdTime) ? (
        <Typography>
          {dayjs(entity.createdTime).format('ll HH:mm:ss')}
        </Typography>
      ) : undefined,
  },
};

export const vaults: FilterProperty<InventoryResource> = {
  name: 'vaults',
  displayName: 'Vaults',
  type: PropertyType.MultipleValues,
  group: 'backup-details',
  column: {
    cell: ({ row }) => {
      if (!row.original?.vaults?.length) return;
      const uniqueIds = Array.from(
        new Set(row.original.vaults.map((x) => x.id))
      );
      return uniqueIds.map((id) => <VaultTag key={id} vault={id} />);
    },
  },
  field: {
    value: (entity) => {
      if (!entity.vaults?.length) return;
      const uniqueIds = Array.from(new Set(entity.vaults.map((x) => x.id)));
      return uniqueIds.map((id) => <VaultTag key={id} vault={id} />);
    },
  },
  filter: {
    Component: (props) => {
      const { data } = useBackupVaults();

      return (
        <MultiSelectFilter
          {...props}
          options={(data || []).map((x) => ({
            id: x.id,
            name: '',
            Image: <VaultTag vault={x} />,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const { data } = useBackupVaults();

      return data.find((x) => x.id === conditionValue)?.name || '...';
    },
  },
};

export const resourceType: FilterProperty<InventoryResource> = {
  name: 'resourceType',
  displayName: 'Resource type',
  type: PropertyType.SingleValue,
  values: [
    'EC2',
    'S3',
    'RDS',
    'Redshift',
    'Lambda',
    'DynamoDB',
    'Blob Container',
    'Virtual Machine',
    'SQL Server',
    'PostgreSQL',
    'MySQL',
  ],
  group: 'primary',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      row.original.resourceType && (
        <Box className='flex items-center'>
          {CloudProviders[row.original.cloudProvider] && (
            <CircleImage
              key={row.original.cloudProvider}
              alt={row.original.cloudProvider}
              src={CloudProviders[row.original.cloudProvider].logo}
              className='mr-[8px]'
            />
          )}
          <Typography>
            {ResourceTypes[row.original.resourceType]?.displayName}
          </Typography>
        </Box>
      ),
  },
  field: {
    value: (entity) =>
      entity.resourceType &&
      entity.cloudProvider &&
      CloudProviders[entity.cloudProvider] && (
        <Box className='flex items-center p-0' sx={{ height: '18px' }}>
          <CircleImage
            alt={CloudProviders[entity.cloudProvider].displayName}
            src={CloudProviders[entity.cloudProvider].logo}
            sx={{ height: '24px', width: '24px' }}
            className='mr-[8px]'
          />
          <Typography>
            {ResourceTypes[entity.resourceType]?.displayName}
          </Typography>
        </Box>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceTypes.list();

      const options =
        body?.resourceTypes.map((x) => ({
          id: x,
          name: ResourceTypes[x]?.displayName,
          image: CloudProviders[ResourceTypes[x]?.provider]?.logo,
          cloudProvider: CloudProviders[ResourceTypes[x]?.provider],
        })) || [];

      return (
        <MultiSelectFilter
          {...props}
          options={
            options
              .sort(
                (a, b) =>
                  (a.cloudProvider?.displayName || '').localeCompare(
                    b.cloudProvider?.displayName || ''
                  ) || a.name.localeCompare(b.name)
              )
              .map((x) => ({
                id: x.id,
                name: x.name,
                Image: x.image && (
                  <CircleImage
                    alt={x.name}
                    src={x.image}
                    className='mr-[8px]'
                  />
                ),
              })) || []
          }
        />
      );
    },
    Badge: ({ conditionValue }) => {
      return ResourceTypes[conditionValue]?.displayName;
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceTypes.list();

      return (
        <PropertySelection
          {...props}
          options={
            body?.resourceTypes.map((x) => ({
              value: x,
              label: ResourceTypes[x]?.displayName,
              image: CloudProviders[ResourceTypes[x]?.provider]?.logo,
            })) || []
          }
        />
      );
    },
  },
};

export const resourceGroupName: FilterProperty<InventoryResource> = {
  name: 'resourceGroupName',
  displayName: 'Resource group',
  type: PropertyType.String,
  group: 'primary',
  column: {
    enableHiding: true,
    enableColumnFilter: true,
    enableSorting: true,
    cell: ({ row }) =>
      row.original.resourceGroupName && (
        <Typography>{row.original.resourceGroupName}</Typography>
      ),
  },
  field: {
    value: (entity) =>
      entity.resourceGroupName && (
        <Typography>{entity.resourceGroupName}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceGroups.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.resourceGroups || []).map((rg) => ({
            id: rg,
            name: rg,
          }))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.resourceGroups.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.resourceGroups.map((rg) => ({
              value: rg,
              label: rg,
            })) || []
          }
        />
      );
    },
  },
};

export const apps: FilterProperty<InventoryResource | FileSearchRecord> = {
  name: 'apps',
  displayName: 'Apps',
  type: PropertyType.MultipleValues,
  values: Object.keys(ApplicationsLogos).filter((x) => x !== 'Unknown'),
  group: 'classifications',
  policyRelation: PropertyPolicyRelation.MultipleValues,
  column: {
    enableSorting: false,
    cell: ({ row }) => {
      const apps =
        ('apps' in row.original
          ? row.original.apps
          : 'classifications' in row.original
            ? row.original.classifications?.appsDetails?.apps?.map(
                (x) => x.name
              )
            : undefined) || [];

      return (
        !!apps.length &&
        apps.map((name) => (
          <Tooltip
            key={name}
            title={
              <Typography
                variant='body2'
                component='span'
                className='text-inherit'
              >
                {name}
              </Typography>
            }
          >
            <CircleImage
              key={name}
              alt={name}
              src={ApplicationsLogosWithAliases[name]}
            />
          </Tooltip>
        ))
      );
    },
  },
  field: {
    value: (entity) => {
      const apps =
        ('apps' in entity
          ? entity.apps
          : 'classifications' in entity
            ? entity.classifications?.appsDetails?.apps?.map((x) => x.name)
            : undefined) || [];

      return (
        !!apps.length &&
        apps.map((name) => (
          <div key={name} className='flex items-center'>
            <CircleImage
              alt={name}
              src={ApplicationsLogosWithAliases[name]}
              className='mr-[8px]'
            />
            <Typography>{name}</Typography>
          </div>
        ))
      );
    },
  },
  filter: {
    openFullSize: {
      title: 'Apps',
      subtitle: 'To filter your resources, select one or more apps',
    },
    Component: AppsFilter,
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.apps.list();
      const opts = Array.from(
        new Set([
          ...(body?.apps ?? []),
          ...Object.keys(ApplicationsLogos).filter((x) => x !== 'Unknown'),
        ])
      );
      return (
        <PropertySelection
          {...props}
          showImagesInRender={true}
          options={
            opts.map((value) => ({
              value,
              label: value,
              image: ApplicationsLogosWithAliases[value],
            })) || []
          }
        />
      );
    },
  },
};

export const dataClasses: FilterProperty<InventoryResource> = {
  name: 'dataClasses',
  displayName: 'Data classes',
  type: PropertyType.MultipleValues,
  values: Object.keys(DataClasses),
  policyRelation: PropertyPolicyRelation.MultipleValues,
  group: 'classifications',
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      !!row.original.classifications?.dataClassesDetails?.dataClasses
        ?.length && (
        <Stack gap='8px' direction='row'>
          {row.original.classifications.dataClassesDetails.dataClasses.map(
            (x) => (
              <Tooltip key={x} title={DataClasses[x]?.title}>
                <Tag variant='filled' content={x} />
              </Tooltip>
            )
          )}
        </Stack>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.classifications?.dataClassesDetails?.dataClasses?.length && (
        <Stack gap='8px' direction='row'>
          {entity.classifications.dataClassesDetails.dataClasses.map((x) => (
            <Tooltip key={x} title={DataClasses[x]?.title}>
              <Tag variant='filled' content={x} />
            </Tooltip>
          ))}
        </Stack>
      ),
  },
  filter: {
    Component: (props) => (
      <MultiSelectFilter
        {...props}
        options={Object.values(DataClass)
          .filter((x) => x !== DataClass.Unspecified)
          .sort((a, b) => a.localeCompare(b))
          .map((id) => ({
            id,
            name: '',
            Image: <Tag variant='filled' content={id} />,
            description: DataClasses[id]?.description,
          }))}
      />
    ),
  },
  dropdown: {
    Component: (props) => (
      <PropertySelection
        {...props}
        options={
          Object.entries(DataClass)
            .filter((x) => x[1] !== DataClass.Unspecified)
            .map(([, label]) => ({ value: label, label })) || []
        }
      />
    ),
  },
};

export const tags: FilterProperty<InventoryResource> = {
  name: 'tags',
  displayName: 'Tags',
  type: PropertyType.MultipleValues,
  policyRelation: PropertyPolicyRelation.Tags,
  values: ['tag1', 'tag2', 'tag3'],
  group: 'primary',
  column: {
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row }) => <TagsElasticContainer tags={row.original.tags} />,
  },
  field: {
    value: (entity) =>
      !!Object.entries(entity.tags || {}).length && (
        <Stack gap='8px' direction='row' flexWrap='wrap'>
          {Object.entries(entity.tags).map((entry) => (
            <Tag
              variant='outlined'
              key={entry[0]}
              content={entry.filter((x) => x).join('=')}
            />
          ))}
        </Stack>
      ),
  },
  filter: {
    Component: TagsFilter,
  },
  textInput: {
    Component: (props) => <TextField {...props} />,
  },
};

export const environment: FilterProperty<InventoryResource | FileSearchRecord> =
  {
    name: 'environment',
    displayName: 'Environment',
    type: PropertyType.SingleValue,
    group: 'classifications',
    policyRelation: PropertyPolicyRelation.SingleValue,
    column: {
      cell: ({ row }) => {
        const environment =
          ('environment' in row.original
            ? row.original.environment
            : 'classifications' in row.original
              ? row.original.classifications?.environmentDetails?.environment
              : undefined) || '';

        return (
          environment &&
          Environments[environment] && (
            <Typography>{Environments[environment].title}</Typography>
          )
        );
      },
    },
    field: {
      value: (entity) => {
        const environment =
          ('environment' in entity
            ? entity.environment
            : 'classifications' in entity
              ? entity.classifications?.environmentDetails?.environment
              : undefined) || '';

        return (
          environment &&
          Environments[environment] && (
            <Typography>{Environments[environment].title}</Typography>
          )
        );
      },
    },
    filter: {
      Component: (props) => {
        const dal = useDAL();
        const { body } = dal.inventory.environments.list();

        return (
          <MultiSelectFilter
            {...props}
            options={(body?.environments || []).map((x) => ({
              id: x,
              name: Environments[x]?.title,
              description: Environments[x]?.description,
            }))}
          />
        );
      },

      Badge: ({ conditionValue }) => {
        return Environments[conditionValue]?.title;
      },
    },
    dropdown: {
      Component: (props) => {
        const dal = useDAL();
        const { body } = dal.inventory.environments.list();

        return (
          <PropertySelection
            {...props}
            options={
              body?.environments.map((x) => ({
                value: x,
                label: Environments[x]?.title,
              })) || []
            }
          />
        );
      },
    },
  };

const getBackupPolicesTags = (backupPolicies: BackupPolicy[]) =>
  !!backupPolicies.length &&
  backupPolicies
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((policy) => <BackupPolicyTag key={policy.id} policy={policy} />);

export const backupPolicies: FilterProperty<InventoryResource> = {
  name: 'backupPolicies',
  displayName: 'Active backup policies',
  type: PropertyType.MultipleValues,
  group: 'backup-details',
  column: {
    enableSorting: false,
    cell: ({ row }) => getBackupPolicesTags(row.original.backupPolicies),
  },
  field: {
    value: (entity) => getBackupPolicesTags(entity.backupPolicies),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.backupPolicy.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.backupPolicies || []).map((x) => ({
            id: x.id,
            name: '',
            Image: <BackupPolicyTag policy={x} />,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.backupPolicy.list();

      return (
        body?.backupPolicies.find((x) => x.id === conditionValue)?.name || '...'
      );
    },
  },
};

const VolumesCloudProviderMapping: Record<string, string> = {
  Azure: 'Disks',
  GCP: 'Disks',
};

export const sourceStorageSize: FilterProperty<InventoryResource> = {
  name: 'sourceStorageSize',
  displayName: 'Source storage size',
  type: PropertyType.String,
  group: 'backup-details',
  column: {
    enableColumnFilter: false,
    cell: ({ row }) => {
      if (
        row.original.sourceStorage.sizeBytes ||
        (row.original.resourceType === ResourceType.S3 &&
          row.original.backupStatus !== BackupStatus.InitialClassification)
      ) {
        return <SourceStorageSizeColumn row={row} />;
      }

      return null;
    },
  },
  field: {
    value: (entity) => {
      if (
        entity.sourceStorage.sizeBytes ||
        (entity.resourceType === ResourceType.S3 &&
          entity.backupStatus !== BackupStatus.InitialClassification)
      ) {
        return (
          <Typography>
            {fileSizeFormatter(entity.sourceStorage.sizeBytes, 1, false)}
          </Typography>
        );
      }
      return null;
    },
  },
};

const SourceStorageSizeColumn = ({ row }: { row: Row<InventoryResource> }) => {
  const files = row.original.sourceStorage.filesCount;
  const volumes = row.original.sourceStorage.volumeCount;

  return (
    <Tooltip
      title={
        !!(files || volumes) && (
          <Typography variant='body2' component='span' className='text-inherit'>
            {!!files && <div>{`# Files: ${files}`}</div>}
            {!!volumes && (
              <div>
                {`# ${VolumesCloudProviderMapping[row.original.cloudProvider] || 'Volumes'}: ${volumes}`}
              </div>
            )}
          </Typography>
        )
      }
    >
      <Typography>
        {fileSizeFormatter(row.original.sourceStorage.sizeBytes, 1, false)}
      </Typography>
    </Tooltip>
  );
};

export const backupStorageSize: FilterProperty<InventoryResource> = {
  name: 'backupStorageSize',
  displayName: 'Eon snapshot size',
  type: PropertyType.String,
  group: 'backup-details',
  column: {
    enableColumnFilter: false,
    cell: ({ row }) =>
      !!row.original.snapshotStorage?.sizeBytes && (
        <Typography>
          {fileSizeFormatter(row.original.snapshotStorage.sizeBytes)}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.snapshotStorage?.sizeBytes && (
        <Typography>
          {fileSizeFormatter(entity.snapshotStorage.sizeBytes)}
        </Typography>
      ),
  },
};

export const genericSnapshotsCount: FilterProperty<InventoryResource> = {
  name: 'genericSnapshotsCount',
  displayName: 'Other snapshot count',
  type: PropertyType.String,
  group: 'backup-details',
  column: {
    enableColumnFilter: false,
    cell: ({ row }) =>
      !!row.original.snapshotStorage?.otherSnapshotCount && (
        <Typography>
          {row.original.snapshotStorage.otherSnapshotCount}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.snapshotStorage?.otherSnapshotCount && (
        <Typography>{entity.snapshotStorage.otherSnapshotCount}</Typography>
      ),
  },
};

const VpcCloudProviderMapping: Record<string, string> = {
  Azure: 'Virtual network',
};

export const vpc: FilterProperty<InventoryResource> = {
  name: 'vpc',
  displayName: 'VPC',
  type: PropertyType.SingleValue,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }) =>
      row.original.resourceProperties?.vpc && (
        <Typography>{row.original.resourceProperties.vpc}</Typography>
      ),
  },
  field: {
    titleKey: (entity) =>
      VpcCloudProviderMapping[entity.cloudProvider] || 'VPC',
    value: (entity) =>
      entity.resourceProperties?.vpc && (
        <Typography>{entity.resourceProperties.vpc}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.networks.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.networks || []).map((x) => ({ id: x, name: x }))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.networks.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.networks.map((value) => ({ value, label: value })) || []
          }
        />
      );
    },
  },
};

export const subnets: FilterProperty<InventoryResource> = {
  name: 'subnets',
  displayName: 'Subnets',
  type: PropertyType.MultipleValues,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.MultipleValues,
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      !!row.original.resourceProperties?.subnets?.length && (
        <Typography>
          {row.original.resourceProperties.subnets.join(', ')}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.resourceProperties?.subnets?.length && (
        <Typography>{entity.resourceProperties.subnets.join(', ')}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.subnets.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.subnets || []).map((x) => ({ id: x, name: x }))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.subnets.list();
      return (
        <PropertySelection
          {...props}
          allowAddingOption={true}
          options={
            body?.subnets.map((value) => ({ value, label: value })) || []
          }
        />
      );
    },
  },
};

export const lastBackup: FilterProperty<InventoryResource | FileSearchRecord> =
  {
    name: 'lastBackup',
    displayName: 'Last backup',
    type: PropertyType.Date,
    group: 'backup-details',
    column: {
      enableColumnFilter: false,
      cell: ({ row }) => {
        const lastBackup =
          'lastBackup' in row.original && row.original.lastBackup;
        if (isDateValid(lastBackup)) {
          return <Typography>{dayjs(lastBackup).format('ll')}</Typography>;
        }

        const generatedOn =
          'snapshots' in row.original &&
          row.original.snapshots?.length &&
          row.original.snapshots[0].generatedOn;

        if (isDateValid(generatedOn)) {
          return <Typography>{dayjs(generatedOn).format('ll')}</Typography>;
        }
      },
    },
    field: {
      value: (entity) => {
        const lastBackup = 'lastBackup' in entity && entity.lastBackup;
        if (isDateValid(lastBackup)) {
          return <Typography>{dayjs(lastBackup).format('ll')}</Typography>;
        }

        const generatedOn =
          'snapshots' in entity &&
          entity.snapshots?.length &&
          entity.snapshots[0].generatedOn;

        if (isDateValid(generatedOn)) {
          return <Typography>{dayjs(generatedOn).format('ll')}</Typography>;
        }
      },
    },
  };

export const sourceRegion: FilterProperty<InventoryResource> = {
  name: 'sourceRegion',
  displayName: 'Source region',
  type: PropertyType.SingleValue,
  group: 'source-location',
  policyRelation: PropertyPolicyRelation.SingleValue,
  column: {
    cell: ({ row }) =>
      row.original.resourceProperties?.sourceRegion && (
        <Typography>
          {formatRegionName(row.original.resourceProperties.sourceRegion)}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      entity.resourceProperties?.sourceRegion && (
        <Typography>
          {formatRegionName(entity.resourceProperties.sourceRegion)}
        </Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.regions.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.regions || [])
            .map((x) => ({ id: x, name: formatRegionName(x) }))
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
      );
    },
  },
  dropdown: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.regions.list();
      return (
        <PropertySelection
          {...props}
          options={
            body?.regions.map((value) => ({
              value,
              label: formatRegionName(value),
            })) || []
          }
        />
      );
    },
  },
};

export const backupRegions: FilterProperty<InventoryResource> = {
  name: 'backupRegions',
  displayName: 'Backup regions',
  type: PropertyType.MultipleValues,
  group: 'backup-details',
  column: {
    enableSorting: false,
    cell: ({ row }) =>
      !!row.original.vaults.length && (
        <Typography>
          {[...new Set(row.original.vaults.map((x) => x.region))]
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}
        </Typography>
      ),
  },
  field: {
    value: (entity) =>
      !!entity.vaults.length && (
        <Typography>
          {[...new Set(entity.vaults.map((x) => x.region))]
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}
        </Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.inventory.backupRegions.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.regions || []).map((x) => ({
            id: x,
            name: x,
          }))}
        />
      );
    },
  },
};

export const controlLevel: FilterProperty<InventoryResource> = {
  name: 'severity',
  displayName: 'Severity',
  type: PropertyType.String,
  group: '',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
  filter: {
    Badge: ({ conditionValue }) => {
      const value = conditionValue.toLowerCase();
      return `${capitalize(value)}${value === 'muted' ? '' : '-severity'} control violations`;
    },
  },
};

export const controlId: FilterProperty<InventoryResource> = {
  name: 'controls',
  displayName: 'Control',
  type: PropertyType.String,
  group: '',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
  filter: {
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.controls.list();

      return body?.controls.find((x) => x.id === conditionValue)?.name || '...';
    },
  },
};

export const mutedControlId: FilterProperty<InventoryResource> = {
  name: 'mutedControls',
  displayName: 'Muted control',
  type: PropertyType.String,
  group: '',
  column: {
    enableHiding: false,
    enableColumnFilter: false,
  },
  filter: {
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.controls.list();

      return body?.controls.find((x) => x.id === conditionValue)?.name || '...';
    },
  },
};

export const backupStatus: FilterProperty<InventoryResource> = {
  name: 'backupStatus',
  displayName: 'Backup status',
  type: PropertyType.String,
  group: '',
  linkedProperties: [controlLevel, controlId, mutedControlId],
  column: {
    minSize: 118,
    size: 118,
    enableHiding: false,
    cell: ({ row }) => {
      const status = BackupStatuses[row.original.backupStatus];
      if (!status) {
        return null;
      }
      return (
        <div className='flex items-center'>
          <BackupStatusIcon
            backupStatus={row.original.backupStatus}
            showTooltip
          />
          <ControlsColumnIndicators
            controlViolations={row.original.controlViolations}
          />
        </div>
      );
    },
  },
  filter: {
    Component: BackupStatusFilter,
    shouldHideBadge: (condition) => condition.operator === StringOperator.NotIn,
    Badge: (props) => {
      return BackupStatuses[props.conditionValue]?.title;
    },
  },
};

export const path: FilterProperty<FileSearchRecord> = {
  name: 'path',
  displayName: 'Path',
  type: PropertyType.String,
  group: 'source-location',
  column: {
    enableColumnFilter: false,
    cell: ({ row }) => {
      const delimiter = identifyDelimiter(row.original.path);

      return (
        row.original.path && (
          <Typography>
            {row.getCanExpand() ? (
              <>
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer', marginRight: '5px' },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <i className='bi-dash-square text-[14px] text-primary align-middle' />
                  ) : (
                    <i className='bi-plus-square text-[14px] text-primary align-middle' />
                  )}
                </button>
                {row.original.path.split(delimiter).pop()}
              </>
            ) : (
              <>{row.original.path}</>
            )}
          </Typography>
        )
      );
    },
  },
  field: {
    value: (entity) => entity.path && <Typography>{entity.path}</Typography>,
  },
};

export const modifiedOn: FilterProperty<
  FileSearchRecord | FileExplorerTableItem
> = {
  name: 'modifiedOn',
  displayName: 'Modified on',
  type: PropertyType.Date,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      if (!row.subRows?.length && row.original.snapshots?.length) {
        return (
          <Typography>
            <i className='bi-calendar2-week text-[14px] text-primary align-middle mr-[5px]'></i>
            {dayjs(row.original.snapshots[0].modifiedOn).format('ll')}
          </Typography>
        );
      }

      return (
        'modifiedOn' in row.original &&
        row.original.modifiedOn && (
          <Typography>
            <i className='bi-calendar2-week text-[14px] text-primary align-middle mr-[5px]'></i>
            {dayjs(row.original.modifiedOn).format('ll')}
          </Typography>
        )
      );
    },
  },
};

export const fileType: FilterProperty<FileSearchRecord> = {
  name: 'fileType',
  displayName: 'File type',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      if (row.original.type === 'd') return null;

      const lastSeg = row.original.path.split('/').pop() || row.original.path;
      const parts = lastSeg.split('.');
      return <Typography>{parts.length > 1 ? parts.pop() : ''}</Typography>;
    },
  },
};

export const fileSize: FilterProperty<
  FileSearchRecord | FileExplorerTableItem
> = {
  name: 'fileSize',
  displayName: 'Size',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      if (
        'snapshots' in row.original &&
        !row.subRows?.length &&
        row.original.snapshots?.length &&
        row.original.snapshots[0].size
      ) {
        return (
          <Typography>
            {fileSizeFormatter(row.original.snapshots[0].size)}
          </Typography>
        );
      }

      return (
        'size' in row.original &&
        row.original.size && (
          <Typography>{fileSizeFormatter(row.original.size)} </Typography>
        )
      );
    },
  },
};

export const snapshotId: FilterProperty<FileExplorerTableItem> = {
  name: 'snapshotId',
  displayName: 'Snapshot ID',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.snapshotId && (
        <Typography>{row.original.snapshotId}</Typography>
      ),
  },
};

export const givenName: FilterProperty<User> = {
  name: 'givenName',
  displayName: 'Name',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.givenName && (
        <Typography>{row.original.givenName}</Typography>
      ),
  },
};

export const email: FilterProperty<User> = {
  name: 'email',
  displayName: 'Email',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.email && <Typography>{row.original.email}</Typography>,
  },
};

export const roles: FilterProperty<User> = {
  name: 'roles',
  displayName: 'Roles',
  type: PropertyType.MultipleValues,
  group: 'primary',
  column: {
    cell: ({ row }) => <RolesColumn row={row} />,
  },
};

const RolesColumn = ({ row }: { row: Row<User> }) => {
  const { currentProjectId } = useUser();
  const { rolesMap } = useRoles();

  const roles = Object.keys(row.original.roleToProjectsMappings).filter(
    (roleId) =>
      row.original.roleToProjectsMappings[roleId].ids?.includes(
        currentProjectId
      )
  );

  return (
    <Typography>
      {roles
        .map((x) => rolesMap[x]?.name)
        .filter((x) => !!x)
        .join(', ')}
    </Typography>
  );
};

export const groupName: FilterProperty<SamlGroup> = {
  name: 'groupName',
  displayName: 'Group name',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.groupName && (
        <Typography>{row.original.groupName}</Typography>
      ),
  },
};

export const samlProviderName: FilterProperty<SamlGroup> = {
  name: 'samlProviderName',
  displayName: 'SAML provider',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.saml.providerName && (
        <Typography>{row.original.saml.providerName}</Typography>
      ),
  },
};

export const operationId: FilterProperty<AuditLog> = {
  name: 'operationId',
  displayName: 'Operation ID',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.operationName && (
        <Typography>{row.original.operationName}</Typography>
      ),
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by ID'} />
    ),
  },
};

export const action: FilterProperty<AuditLog> = {
  name: 'action',
  displayName: 'Action',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.action && (
        <Typography className='capitalize'>{row.original.action}</Typography>
      ),
  },
  filter: {
    Component: (props) => {
      const options = ['create', 'update', 'delete', 'trigger', 'other'].map(
        (id) => ({ id, name: capitalize(id) })
      );

      return <MultiSelectFilter {...props} options={options || []} />;
    },
  },
};

export const requestTime: FilterProperty<AuditLog> = {
  name: 'requestTime',
  displayName: 'Request time',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.requestDetails?.requestTime && (
        <Typography>
          {dayjs(row.original.requestDetails.requestTime).format('lll')}
        </Typography>
      ),
  },
};

export const statusCode: FilterProperty<AuditLog> = {
  name: 'statusCode',
  displayName: 'Status code',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => <Typography>{row.original.statusCode}</Typography>,
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by code'} />
    ),
  },
};

const jobStatusColorsMap: Record<string, string> = {
  completed: 'var(--mui-palette-success-main)',
  failed: 'var(--mui-palette-error-main)',
  pending: 'var(--mui-palette-warning-main)',
  running: 'var(--mui-palette-info-main)',
};

export const jobId: FilterProperty<Job> = {
  name: 'id',
  displayName: 'Job ID',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.id && <Typography>{row.original.id}</Typography>,
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by ID'} />
    ),
  },
};

export const jobStatus: FilterProperty<Job> = {
  name: 'status',
  displayName: 'Status',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      const status = row.original.status?.replace('JOB_', '').toLowerCase();
      return (
        status && (
          <Tooltip title={row.original.error}>
            <Typography
              className='capitalize'
              color={
                jobStatusColorsMap[status] ||
                'var(--mui-palette-secondary-main)'
              }
            >
              {status}
            </Typography>
          </Tooltip>
        )
      );
    },
  },
  filter: {
    Component: (props) => {
      const options = ['PENDING', 'RUNNING', 'COMPLETED', 'FAILED'].map(
        (id) => ({
          id,
          name: capitalize(id.toLowerCase()),
        })
      );

      return <MultiSelectFilter {...props} options={options || []} />;
    },
  },
};

export const startedAt: FilterProperty<Job> = {
  name: 'startedAt',
  displayName: 'Started at',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.startedAt && (
        <Typography>{dayjs(row.original.startedAt).format('lll')}</Typography>
      ),
  },
};

export const initiatorId: FilterProperty<Job> = {
  name: 'initiatorId',
  displayName: 'Initiated by',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }: { row: Row<Job> }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dal = useDAL();
      const { body } = dal.users.list();
      if (!row.original.initiatorId) {
        return null;
      }
      return (
        <Typography>
          {body?.users.find((a) => a.id === row.original.initiatorId)
            ?.givenName ?? row.original.initiatorId}
        </Typography>
      );
    },
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.users.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.users || []).map((a) => ({
            id: a.id,
            name: a.givenName,
          }))}
        />
      );
    },
    Badge: ({ conditionValue }) => {
      const dal = useDAL();
      const { body } = dal.users.list();

      return (
        body?.users.find((x) => x.id === conditionValue)?.givenName || '...'
      );
    },
  },
};

export const targetAccountId: FilterProperty<Job> = {
  name: 'targetAccountId',
  displayName: 'Target account',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }: { row: Row<Job> }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const dal = useDAL();
      const { body } = dal.cloudAccounts.restore.list();
      if (!row.original.userId) {
        return null;
      }
      return (
        <Typography>
          {body?.accounts.find((a) => a.id === row.original.targetAccountId)
            ?.name ?? row.original.targetAccountId}
        </Typography>
      );
    },
  },
  filter: {
    Component: (props) => {
      const dal = useDAL();
      const { body } = dal.cloudAccounts.restore.list();

      return (
        <MultiSelectFilter
          {...props}
          options={(body?.accounts || []).map((a) => ({
            id: a.id,
            name: a.name,
          }))}
        />
      );
    },
  },
};

export const targetRegion: FilterProperty<Job> = {
  name: 'targetRegion',
  displayName: 'Target region',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.targetRegion && (
        <Typography>{row.original.targetRegion}</Typography>
      ),
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by region'} />
    ),
  },
};

export const updatedAt: FilterProperty<Job> = {
  name: 'updatedAt',
  displayName: 'Completed at',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => {
      if (row.original.status?.includes('COMPLETED')) {
        return (
          <Typography>{dayjs(row.original.updatedAt).format('lll')}</Typography>
        );
      }
    },
  },
};

export const onDemand: FilterProperty<Job> = {
  name: 'onDemand',
  displayName: 'On demand',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => (
      <Typography className='capitalize'>
        {Boolean(row.original.onDemand).toString()}
      </Typography>
    ),
  },
};

export const partial: FilterProperty<Job> = {
  name: 'partial',
  displayName: 'Is partial',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) => (
      <Typography className='capitalize'>
        {Boolean(row.original.partial).toString()}
      </Typography>
    ),
  },
};

export const restoreType: FilterProperty<Job> = {
  name: 'type',
  displayName: 'Restore type',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.type && <Typography>{row.original.type}</Typography>,
  },
  filter: {
    Component: (props) => (
      <FreeTextFilter {...props} placeholder={'Search by type'} />
    ),
  },
};

export const vaultId: FilterProperty<Job> = {
  name: 'vaultId',
  displayName: 'Vault',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.vaultId && <VaultTag vault={row.original.vaultId} />,
  },
};

export const dbEngine: FilterProperty<InventoryResource> = {
  name: 'dbEngine',
  displayName: 'Engine',
  type: PropertyType.String,
  group: 'primary',
  field: {
    value: (entity) =>
      entity.databaseProperties?.engine && (
        <Box className='flex items-center'>
          <CircleImage
            alt={entity.databaseProperties.engine}
            src={ApplicationsLogosWithAliases[entity.databaseProperties.engine]}
            className='mr-[8px]'
          />
          <Typography className='capitalize'>
            {entity.databaseProperties.engine +
              (entity.databaseProperties.engineVersion
                ? ` (${entity.databaseProperties.engineVersion})`
                : '')}
          </Typography>
        </Box>
      ),
  },
};

export const timestamp: FilterProperty<Notification> = {
  name: 'timestamp',
  displayName: 'Timestamp',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.timestamp && (
        <Typography>{dayjs(row.original.timestamp).format('lll')}</Typography>
      ),
  },
};

export const backupPolicy: FilterProperty<Notification> = {
  name: 'policyId',
  displayName: 'Backup Policy',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.policyId && (
        <BackupPolicyTagFromId policyId={row.original.policyId} />
      ),
  },
};

export const notificationType: FilterProperty<Notification> = {
  name: 'notificationType',
  displayName: 'Type',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.type && (
        <Typography>{NotificationTypes[row.original.type]?.title}</Typography>
      ),
  },
};

export const notificationStatus: FilterProperty<Notification> = {
  name: 'status',
  displayName: 'Status',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.status && (
        <Typography>
          {NotificationStatuses[row.original.status]?.title}
        </Typography>
      ),
  },
};

export const notificationPolicyName: FilterProperty<Notification> = {
  name: 'policyName',
  displayName: 'Policy Name',
  type: PropertyType.String,
  group: 'primary',
  column: {
    cell: ({ row }) =>
      row.original.policyName && (
        <Typography>{row.original.policyName}</Typography>
      ),
  },
};
