import type {
  Configuration,
  ListNotificationsRequest,
  NotificationPolicy,
} from '@repo/api-gw-sdk';
import { NotificationsApi } from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/notifications';

export const notifications = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const notificationsApi = new NotificationsApi(config);

  return {
    list: (
      pageToken?: string,
      pageSize?: number,
      listNotificationsRequest?: ListNotificationsRequest
    ) =>
      httpClient.execute(
        [currentProjectId, pageToken, pageSize, listNotificationsRequest],
        () =>
          notificationsApi.listNotifications(
            currentProjectId,
            pageToken,
            pageSize,
            listNotificationsRequest
          )
      ),
    policies: {
      list: () =>
        httpClient.execute([baseUrl, 'policies', currentProjectId], () =>
          notificationsApi.listNotificationPolicies(currentProjectId)
        ),
      create: (notificationPolicy: NotificationPolicy) =>
        notificationsApi.createNotificationPolicy(
          currentProjectId,
          notificationPolicy
        ),
      update: (notificationPolicy: NotificationPolicy) =>
        notificationsApi.updateNotificationPolicy(
          notificationPolicy.id,
          currentProjectId,
          notificationPolicy
        ),
      delete: (id: string) =>
        notificationsApi.deleteNotificationPolicy(id, currentProjectId),
    },
  };
};
