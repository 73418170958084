/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { FilePath } from '../models/FilePath';
import { S3RestoreTarget } from '../models/S3RestoreTarget';
import { StorageAccountRestoreTarget } from '../models/StorageAccountRestoreTarget';

export class RestoreFilesRequest {
  /**
  * List of file paths to restore.
  */
  'files': Array<FilePath>;
  /**
  * Eon-assigned ID of the restore account.
  */
  'restoreAccountId': string;
  /**
  * Prefix to add to the restore path. If you don\'t specify a prefix, the files are restored to their respective folders in the original file tree, starting from the root of the bucket. 
  */
  'prefix'?: string;
  's3Target'?: S3RestoreTarget;
  'storageAccountTarget'?: StorageAccountRestoreTarget;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "files",
      "baseName": "files",
      "type": "Array<FilePath>",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "prefix",
      "baseName": "prefix",
      "type": "string",
      "format": ""
    },
    {
      "name": "s3Target",
      "baseName": "s3Target",
      "type": "S3RestoreTarget",
      "format": ""
    },
    {
      "name": "storageAccountTarget",
      "baseName": "storageAccountTarget",
      "type": "StorageAccountRestoreTarget",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreFilesRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

