import { List, ListItemButton, Radio, Typography } from '@mui/material';
import type { Account, AccountCloudEnum } from '@repo/api-gw-sdk';
import { type Dispatch, type SetStateAction } from 'react';

import { Loader } from '@/components/layout/loading';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { StepContainer } from '../../wizard/StepContainer';

interface RestoreAccountProps {
  supportedCloudProviders: AccountCloudEnum[];
  restoreAccount?: Account;
}

export type SelectTargetAccountStepProps<T> = StepProps<
  T & RestoreAccountProps
>;

export const SelectTargetAccountStep = <T,>(
  props: SelectTargetAccountStepProps<T>
) => {
  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={0}
      onBackClick={props.back}
      canGoNext={() => !!props.wizardState.restoreAccount}
      onNextClick={() => props.setNextStep(props.currentStep.next?.[0])}
    >
      <SelectTargetAccount
        {...props.wizardState}
        setRestoreAccountState={props.setWizardState}
      />
    </StepContainer>
  );
};

interface SelectTargetAccountProps<T> extends RestoreAccountProps {
  setRestoreAccountState: Dispatch<
    SetStateAction<
      T & {
        restoreAccount?: Account;
      }
    >
  >;
  title?: string;
}

export const SelectTargetAccount = <T,>(props: SelectTargetAccountProps<T>) => {
  const dal = useDAL();
  const { body } = dal.cloudAccounts.restore.list();

  if (!body) {
    return <Loader />;
  }

  const onRestoreAccountClicked = (account: Account) => {
    props.setRestoreAccountState((state) => ({
      ...state,
      restoreAccount: account,
    }));
  };

  return (
    <>
      <Typography component={'span'}>
        {props.title || 'Select an account to restore to:'}
      </Typography>
      <List className='mt-[20px] mb-[10px]'>
        {body.accounts
          .filter(
            (account) =>
              !props.supportedCloudProviders ||
              props.supportedCloudProviders.includes(account.cloud)
          )
          .map((account) => {
            const isChecked = props.restoreAccount?.id === account.id;
            return (
              <ListItemButton
                key={account.id}
                sx={{
                  border: isChecked
                    ? '2px solid var(--mui-palette-primary-main)'
                    : '1px solid var(--mui-palette-divider)',
                  marginBottom: '8px',
                }}
                onClick={() => onRestoreAccountClicked(account)}
                className='flex'
              >
                <Radio className='p-0' checked={isChecked} value={account.id} />
                <Typography className='flex-grow'>{account.name}</Typography>
                {account.providerAccountId !== account.name && (
                  <Typography component={'span'}>
                    {account.providerAccountId}
                  </Typography>
                )}
              </ListItemButton>
            );
          })}
      </List>
    </>
  );
};
