import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { GetSnapshotResponse } from '../models/GetSnapshotResponse';
import { ListInventorySnapshotsResponse } from '../models/ListInventorySnapshotsResponse';
import { RestoreBucketRequest } from '../models/RestoreBucketRequest';
import { RestoreDbToRdsInstanceRequest } from '../models/RestoreDbToRdsInstanceRequest';
import { RestoreDbToRdsSnapshotRequest } from '../models/RestoreDbToRdsSnapshotRequest';
import { RestoreFilesRequest } from '../models/RestoreFilesRequest';
import { RestoreJobInitiationResponse } from '../models/RestoreJobInitiationResponse';
import { RestoreVolumeToEbsRequest } from '../models/RestoreVolumeToEbsRequest';
import { RestoreVolumeToEbsSnapshotRequest } from '../models/RestoreVolumeToEbsSnapshotRequest';

/**
 * no description
 */
export class SnapshotsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get Snapshot
   * @param id ID of the Eon snapshot to retrieve.
   * @param projectId [ID of the project](/global-settings/projects) the snapshot is in. 
   */
  public async getSnapshot(id: string, projectId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "getSnapshot", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "getSnapshot", "projectId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/snapshots/{id}'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * List Resource Snapshots
   * @param id ID of the resource whose Eon snapshots you want to retrieve.
   * @param projectId [ID of the project](/global-settings/projects) the resource is in. 
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Max number of items to return in the next page.
   */
  public async listResourceSnapshots(id: string, projectId: string, pageToken?: string, pageSize?: number, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "listResourceSnapshots", "id");
    }


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "listResourceSnapshots", "projectId");
    }




    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots'
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageToken !== undefined) {
      requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Restore Bucket
   * @param projectId [ID of the project](/global-settings/projects) the snapshot is in. 
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreBucketRequest 
   */
  public async restoreBucket(projectId: string, id: string, snapshotId: string, restoreBucketRequest: RestoreBucketRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreBucket", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreBucket", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreBucket", "snapshotId");
    }


    // verify required parameter 'restoreBucketRequest' is not null or undefined
    if (restoreBucketRequest === null || restoreBucketRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreBucket", "restoreBucketRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots/{snapshotId}/restore-bucket'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(restoreBucketRequest, "RestoreBucketRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Restore Database
   * @param projectId [ID of the project](/global-settings/projects) the snapshot is in. 
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreDbToRdsInstanceRequest 
   */
  public async restoreDbToRdsInstance(projectId: string, id: string, snapshotId: string, restoreDbToRdsInstanceRequest: RestoreDbToRdsInstanceRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsInstance", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsInstance", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsInstance", "snapshotId");
    }


    // verify required parameter 'restoreDbToRdsInstanceRequest' is not null or undefined
    if (restoreDbToRdsInstanceRequest === null || restoreDbToRdsInstanceRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsInstance", "restoreDbToRdsInstanceRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots/{snapshotId}/restore-db-to-rds-instance'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(restoreDbToRdsInstanceRequest, "RestoreDbToRdsInstanceRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Start a database restore from snapshot
   * @param projectId The project ID
   * @param id The inventory item ID
   * @param snapshotId The snapshot ID
   * @param restoreDbToRdsSnapshotRequest The request body for restoring files
   */
  public async restoreDbToRdsSnapshot(projectId: string, id: string, snapshotId: string, restoreDbToRdsSnapshotRequest: RestoreDbToRdsSnapshotRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsSnapshot", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsSnapshot", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsSnapshot", "snapshotId");
    }


    // verify required parameter 'restoreDbToRdsSnapshotRequest' is not null or undefined
    if (restoreDbToRdsSnapshotRequest === null || restoreDbToRdsSnapshotRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreDbToRdsSnapshot", "restoreDbToRdsSnapshotRequest");
    }


    // Path Params
    const localVarPath = '/projects/{projectId}/inventory/{id}/snapshots/{snapshotId}/restore-db-to-rds-snapshot'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(restoreDbToRdsSnapshotRequest, "RestoreDbToRdsSnapshotRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Restore Files
   * @param projectId [ID of the project](/global-settings/projects) the snapshot is in. 
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreFilesRequest 
   */
  public async restoreFiles(projectId: string, id: string, snapshotId: string, restoreFilesRequest: RestoreFilesRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreFiles", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreFiles", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreFiles", "snapshotId");
    }


    // verify required parameter 'restoreFilesRequest' is not null or undefined
    if (restoreFilesRequest === null || restoreFilesRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreFiles", "restoreFilesRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots/{snapshotId}/restore-files'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(restoreFilesRequest, "RestoreFilesRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Restore Volume
   * @param projectId [ID of the project](/global-settings/projects) the snapshot is in. 
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreVolumeToEbsRequest 
   */
  public async restoreVolumeToEbs(projectId: string, id: string, snapshotId: string, restoreVolumeToEbsRequest: RestoreVolumeToEbsRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbs", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbs", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbs", "snapshotId");
    }


    // verify required parameter 'restoreVolumeToEbsRequest' is not null or undefined
    if (restoreVolumeToEbsRequest === null || restoreVolumeToEbsRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbs", "restoreVolumeToEbsRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots/{snapshotId}/restore'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(restoreVolumeToEbsRequest, "RestoreVolumeToEbsRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Restore Volume to EBS Snapshot
   * @param projectId [ID of the project](/global-settings/projects) the snapshot is in. 
   * @param id Eon-assigned resource ID.
   * @param snapshotId ID of the Eon snapshot to restore.
   * @param restoreVolumeToEbsSnapshotRequest 
   */
  public async restoreVolumeToEbsSnapshot(projectId: string, id: string, snapshotId: string, restoreVolumeToEbsSnapshotRequest: RestoreVolumeToEbsSnapshotRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbsSnapshot", "projectId");
    }


    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbsSnapshot", "id");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbsSnapshot", "snapshotId");
    }


    // verify required parameter 'restoreVolumeToEbsSnapshotRequest' is not null or undefined
    if (restoreVolumeToEbsSnapshotRequest === null || restoreVolumeToEbsSnapshotRequest === undefined) {
      throw new RequiredError("SnapshotsApi", "restoreVolumeToEbsSnapshot", "restoreVolumeToEbsSnapshotRequest");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/resources/{id}/snapshots/{snapshotId}/convert'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'id' + '}', encodeURIComponent(String(id)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(restoreVolumeToEbsSnapshotRequest, "RestoreVolumeToEbsSnapshotRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class SnapshotsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getSnapshot
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getSnapshotWithHttpInfo(response: ResponseContext): Promise<HttpInfo<GetSnapshotResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: GetSnapshotResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "GetSnapshotResponse", ""
      ) as GetSnapshotResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Snapshot wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: GetSnapshotResponse = await response.body.json() as GetSnapshotResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listResourceSnapshots
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listResourceSnapshotsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListInventorySnapshotsResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListInventorySnapshotsResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListInventorySnapshotsResponse", ""
      ) as ListInventorySnapshotsResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Resource wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListInventorySnapshotsResponse = await response.body.json() as ListInventorySnapshotsResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to restoreBucket
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async restoreBucketWithHttpInfo(response: ResponseContext): Promise<HttpInfo<RestoreJobInitiationResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: RestoreJobInitiationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "RestoreJobInitiationResponse", ""
      ) as RestoreJobInitiationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Project, resource, or snapshot wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: RestoreJobInitiationResponse = await response.body.json() as RestoreJobInitiationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to restoreDbToRdsInstance
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async restoreDbToRdsInstanceWithHttpInfo(response: ResponseContext): Promise<HttpInfo<RestoreJobInitiationResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: RestoreJobInitiationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "RestoreJobInitiationResponse", ""
      ) as RestoreJobInitiationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Project, resource, or snapshot wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: RestoreJobInitiationResponse = await response.body.json() as RestoreJobInitiationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to restoreDbToRdsSnapshot
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async restoreDbToRdsSnapshotWithHttpInfo(response: ResponseContext): Promise<HttpInfo<RestoreJobInitiationResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: RestoreJobInitiationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "RestoreJobInitiationResponse", ""
      ) as RestoreJobInitiationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Snapshot or restore account not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: RestoreJobInitiationResponse = await response.body.json() as RestoreJobInitiationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to restoreFiles
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async restoreFilesWithHttpInfo(response: ResponseContext): Promise<HttpInfo<RestoreJobInitiationResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: RestoreJobInitiationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "RestoreJobInitiationResponse", ""
      ) as RestoreJobInitiationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Project, resource, or snapshot wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: RestoreJobInitiationResponse = await response.body.json() as RestoreJobInitiationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to restoreVolumeToEbs
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async restoreVolumeToEbsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<RestoreJobInitiationResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: RestoreJobInitiationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "RestoreJobInitiationResponse", ""
      ) as RestoreJobInitiationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Volume, snapshot, or restore account wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: RestoreJobInitiationResponse = await response.body.json() as RestoreJobInitiationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to restoreVolumeToEbsSnapshot
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async restoreVolumeToEbsSnapshotWithHttpInfo(response: ResponseContext): Promise<HttpInfo<RestoreJobInitiationResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: RestoreJobInitiationResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "RestoreJobInitiationResponse", ""
      ) as RestoreJobInitiationResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Volume, snapshot, or restore account not found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: RestoreJobInitiationResponse = await response.body.json() as RestoreJobInitiationResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
