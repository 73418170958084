/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreInstanceVolumeInput } from '../models/RestoreInstanceVolumeInput';

export class RestoreInstanceInput {
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The region to restore the instance to
  */
  'region': string;
  /**
  * The instance type to use for the output instance
  */
  'instanceType': string;
  /**
  * The subnet ID to deploy the output instance to
  */
  'subnetId': string;
  /**
  * Optional security group IDs to apply to the output instance
  */
  'securityGroupIds'?: Array<string>;
  /**
  * Optional instance profile name to apply to the output instance
  */
  'instanceProfileName'?: string;
  /**
  * Optional tags to apply to the output instance
  */
  'tags': { [key: string]: string; };
  'volumeSettings': Array<RestoreInstanceVolumeInput>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "instanceType",
      "baseName": "instanceType",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnetId",
      "baseName": "subnetId",
      "type": "string",
      "format": ""
    },
    {
      "name": "securityGroupIds",
      "baseName": "securityGroupIds",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "instanceProfileName",
      "baseName": "instanceProfileName",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "Array<RestoreInstanceVolumeInput>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreInstanceInput.attributeTypeMap;
  }

  public constructor() {
  }
}

