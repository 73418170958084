/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class ConvertOtherSnapshotsOnDemandRequest {
  /**
  * The ID of the vault
  */
  'vaultId': string;
  /**
  * The number of days to retain the backup
  */
  'retentionDays': number;
  /**
  * the cloud snapshot IDs to be used for the backup
  */
  'genericSnapshotIds': Array<string>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "vaultId",
      "baseName": "vaultId",
      "type": "string",
      "format": ""
    },
    {
      "name": "retentionDays",
      "baseName": "retentionDays",
      "type": "number",
      "format": ""
    },
    {
      "name": "genericSnapshotIds",
      "baseName": "genericSnapshotIds",
      "type": "Array<string>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ConvertOtherSnapshotsOnDemandRequest.attributeTypeMap;
  }

  public constructor() {
  }
}

