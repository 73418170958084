import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import { parseJson } from '@/utils/string';

import { flagsContext } from './LaunchDarklyProvider';
import { useEnvironment } from './useEnvironment';

export const useFeatureFlags = () => {
  const conductedFlags = useFlags(flagsContext);
  const searchParams = useSearchParams();
  const flagsOverrides = searchParams.get('flags');

  const result = useMemo(() => {
    if (!flagsOverrides) {
      return conductedFlags;
    }

    const flags = flagsOverrides.split(',').reduce((acc, flag) => {
      const parts = flag.split(':');
      const [key, value] = parts;
      if (parts.length === 1) {
        return { ...acc, [key]: true };
      }

      const parsedValue = parseJson(value);
      return { ...acc, [key]: parsedValue === undefined ? value : parsedValue };
    }, {});

    return {
      ...conductedFlags,
      ...flags,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return result;
};

export const useLaunchDarkly = () => {
  const { isDemo, isDev } = useEnvironment();
  const [ldClientReady, setLDClientReady] = useState(isDemo || isDev);
  const ldClient = useLDClient(flagsContext);

  useEffect(() => {
    void ldClient?.waitUntilReady().then(() => setLDClientReady(true));
  }, [ldClient]);

  return { isReady: ldClientReady };
};
