import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  MenuItem,
  Radio,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import type { Account } from '@repo/api-gw-sdk';
import { useEffect, type Dispatch, type SetStateAction } from 'react';

import { OptionListItem } from '@/components/shared/optionListItem';
import { TransactionalTextField } from '@/components/shared/transactionalTextField';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import type { RestoreGenericStorageProps } from './bucketSelection';

import SelectPlaceholder from '../selectPlaceholder';

type StorageAccountSelectionStepProps<T> = StepProps<
  T & { restoreAccountId?: string }
>;

export const StorageAccountSelectionStep = <
  T extends RestoreGenericStorageProps,
>(
  props: StorageAccountSelectionStepProps<T>
) => {
  const { setWizardState: setStorageAccountSelectionState } = props;
  const dal = useDAL();
  const { body: pref } = dal.preferences.getUserPref(
    PreferencesKey.RestoreStorageAccount
  );

  useEffect(() => {
    if (pref?.value?.accountName) {
      setStorageAccountSelectionState((state) => ({
        ...state,
        StorageAccount: {
          ...state.StorageAccount,
          AccountName: pref.value!.accountName,
          AccountNameOverride: pref.value!.accountName,
          Container: pref.value!.container,
          ContainerOverride: pref.value!.container,
        },
      }));
    }
  }, [pref, setStorageAccountSelectionState]);

  return (
    <StorageAccountSelection
      {...props.wizardState}
      setStorageAccountSelectionState={setStorageAccountSelectionState}
    />
  );
};

interface StorageAccountProps<T> extends RestoreGenericStorageProps {
  restoreAccount?: Account;
  setStorageAccountSelectionState: Dispatch<
    SetStateAction<
      T & {
        restoreAccount?: Account;
      }
    >
  >;
}

export const StorageAccountSelection = <T extends RestoreGenericStorageProps>(
  props: StorageAccountProps<T>
) => {
  const {
    restoreAccount,
    restoreMode,
    prefix,
    StorageAccount,
    setStorageAccountSelectionState,
  } = props;
  const isSelectMode = restoreMode === 'select';
  const isManualMode = restoreMode === 'manual';

  const dal = useDAL();
  const { body: storageAccountsResponse } = dal.restore.storageAccounts.list(
    restoreAccount?.id ?? ''
  );
  const selectedStorageAccount = storageAccountsResponse?.storageAccounts?.find(
    (x) => x.name === StorageAccount.AccountName
  );
  const { body: containersResponse } =
    dal.restore.storageAccounts.listContainers(
      restoreAccount?.id ?? '',
      selectedStorageAccount?.name ?? '',
      selectedStorageAccount?.resourceGroup ?? ''
    );
  const setMode = (mode: 'select' | 'manual') =>
    setStorageAccountSelectionState((state) => ({
      ...state,
      restoreMode: mode,
    }));

  const setRestoreStorageAccountName = (value: string) => {
    setStorageAccountSelectionState((state) => ({
      ...state,
      StorageAccount: {
        ...state.StorageAccount,
        AccountName: value,
        Container: '',
      },
    }));
  };

  const setRestoreStorageAccountNameOverride = (value: string) => {
    setStorageAccountSelectionState((state) => ({
      ...state,
      StorageAccount: { ...state.StorageAccount, AccountNameOverride: value },
    }));
  };

  const setContainer = (value: string) => {
    setStorageAccountSelectionState((state) => ({
      ...state,
      StorageAccount: { ...state.StorageAccount, Container: value },
    }));
  };

  const setContainerOverride = (value: string) => {
    setStorageAccountSelectionState((state) => ({
      ...state,
      StorageAccount: { ...state.StorageAccount, ContainerOverride: value },
    }));
  };

  const setPrefix = (value: string) => {
    setStorageAccountSelectionState((state) => ({
      ...state,
      prefix: value,
    }));
  };

  return (
    <Box className='mx-[40px] my-[24px]'>
      <Box className='flex justify-between items-center'>
        <Typography variant='body1'>Choose a Storage Account</Typography>
      </Box>
      <List className='mt-[20px]'>
        <OptionListItem
          className='pl-[24px]'
          isChecked={isSelectMode}
          onClick={() => {
            setMode('select');
          }}
        >
          <FormControlLabel
            checked={isSelectMode}
            onClick={() => {
              setMode('select');
            }}
            control={<Radio className='pr-[12px]' />}
            label='Select a Storage Account'
          />
          <Collapse className='w-full pl-[36px] pr-[40px]' in={isSelectMode}>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              <FormControl size='small' className='flex-1'>
                <FormLabel>Storage Account*</FormLabel>
                <Select
                  displayEmpty
                  renderValue={(value: string) => {
                    if (!value) {
                      return <SelectPlaceholder />;
                    }
                    const storageAccount =
                      storageAccountsResponse?.storageAccounts?.find(
                        (x) => x.name === value
                      );
                    if (!storageAccount) {
                      return <SelectPlaceholder />;
                    }
                    return <StorageAccountRegionItem {...storageAccount} />;
                  }}
                  className='h-[58px]'
                  size='small'
                  value={
                    storageAccountsResponse?.storageAccounts?.find(
                      (x) => x.name === StorageAccount.AccountName
                    )?.name || ''
                  }
                  onChange={(event) =>
                    setRestoreStorageAccountName(event.target.value)
                  }
                >
                  {(storageAccountsResponse?.storageAccounts || []).map(
                    (storageAccount) => (
                      <MenuItem
                        key={storageAccount.name}
                        value={storageAccount.name}
                      >
                        <StorageAccountRegionItem {...storageAccount} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl size='small' className='flex-1'>
                <FormLabel>Container*</FormLabel>
                <Select
                  displayEmpty
                  disabled={!StorageAccount.AccountName}
                  className='h-[58px]'
                  size='small'
                  value={StorageAccount.Container || ''}
                  onChange={(event) => setContainer(event.target.value)}
                >
                  {(containersResponse?.containers || []).map((container) => (
                    <MenuItem key={container} value={container}>
                      {container}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              <StorageAccountPrefix prefix={prefix} onChange={setPrefix} />
            </Stack>
          </Collapse>
        </OptionListItem>
        <OptionListItem
          className='pl-[24px]'
          isChecked={isManualMode}
          onClick={() => setMode('manual')}
        >
          <FormControlLabel
            checked={isManualMode}
            onClick={() => setMode('manual')}
            control={<Radio className='pr-[12px]' />}
            label='Enter a Storage Account name'
          />
          <Collapse className='w-full pl-[36px] pr-[40px]' in={isManualMode}>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              <FormControl size='small' className='w-1/2'>
                <FormLabel>Storage Account name*</FormLabel>
                <TransactionalTextField
                  initValue={StorageAccount.AccountNameOverride || ''}
                  onChange={setRestoreStorageAccountNameOverride}
                />
              </FormControl>
              <FormControl size='small' className='w-1/2'>
                <FormLabel>Container name*</FormLabel>
                <TransactionalTextField
                  initValue={StorageAccount.ContainerOverride || ''}
                  onChange={setContainerOverride}
                />
              </FormControl>
            </Stack>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              <StorageAccountPrefix prefix={prefix} onChange={setPrefix} />
            </Stack>
          </Collapse>
        </OptionListItem>
      </List>
    </Box>
  );
};

const StorageAccountRegionItem = (props: {
  name: string;
  location: string;
}) => {
  return (
    <Stack direction={'column'} className='py-[4px]'>
      <Typography>{props.name}</Typography>
      <Typography variant='body2' className='mt-[4px]'>
        {props.location}
      </Typography>
    </Stack>
  );
};

const StorageAccountPrefix = (props: {
  prefix?: string;
  onChange: (value: string) => void;
}) => {
  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Prefix (optional)</FormLabel>
      <Stack height='100%' direction='column' justifyContent='space-between'>
        <TransactionalTextField
          initValue={props.prefix || ''}
          onChange={props.onChange}
        />
        <Typography paddingTop='8px' variant='body2'>
          Example: /Project/WordFiles/
        </Typography>
      </Stack>
    </FormControl>
  );
};
