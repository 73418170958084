/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Information about the S3 bucket where you want to restore the files. If you don\'t specify a target, the files are restored to the original bucket. 
*/
export class S3RestoreTarget {
  /**
  * Name of an existing bucket to restore the files to.
  */
  'bucketName': string;
  /**
  * ID of the key you want Eon to use for encrypting the restored files.
  */
  'encryptionKeyId'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "bucketName",
      "baseName": "bucketName",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return S3RestoreTarget.attributeTypeMap;
  }

  public constructor() {
  }
}

