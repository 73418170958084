import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  MenuItem,
  Radio,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import type { Account } from '@repo/api-gw-sdk';
import { useEffect, type Dispatch, type SetStateAction } from 'react';

import { OptionListItem } from '@/components/shared/optionListItem';
import { TransactionalTextField } from '@/components/shared/transactionalTextField';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import { EmptySelect } from '../regions/emptySelect';
import SelectPlaceholder from '../selectPlaceholder';

export interface RestoreGenericStorageProps {
  restoreMode: 'select' | 'manual';
  prefix?: string;
  S3: { Bucket?: string; BucketOverride?: string };
  StorageAccount: {
    AccountName?: string;
    AccountNameOverride?: string;
    Container?: string;
    ContainerOverride?: string;
  };
}

type BucketSelectionStepProps<T> = StepProps<T & { restoreAccount?: Account }>;

export const BucketSelectionStep = <T extends RestoreGenericStorageProps>(
  props: BucketSelectionStepProps<T>
) => {
  const { setWizardState: setBucketSelectionState } = props;
  const dal = useDAL();
  const { body: pref } = dal.preferences.getUserPref(
    PreferencesKey.RestoreBucket
  );

  useEffect(() => {
    if (pref?.value?.bucketName) {
      setBucketSelectionState((state) => ({
        ...state,
        S3: {
          ...state.S3,
          Bucket: pref.value!.bucketName,
          BucketOverride: pref.value!.bucketName,
        },
      }));
    }
  }, [pref, setBucketSelectionState]);

  return (
    <BucketSelection
      {...props.wizardState}
      restoreAccount={props.wizardState.restoreAccount}
      setBucketSelectionState={setBucketSelectionState}
    />
  );
};

interface BucketSelectionProps<T> extends RestoreGenericStorageProps {
  restoreAccount?: Account;
  setBucketSelectionState: Dispatch<
    SetStateAction<
      T & {
        restoreAccount?: Account;
      }
    >
  >;
}

export const BucketSelection = <T extends RestoreGenericStorageProps>(
  props: BucketSelectionProps<T>
) => {
  const { restoreAccount, restoreMode, prefix, S3, setBucketSelectionState } =
    props;
  const isSelectMode = restoreMode === 'select';
  const isManualMode = restoreMode === 'manual';

  const dal = useDAL();
  const { body: s3BucketsResponse } = dal.restore.s3Buckets.list(
    restoreAccount?.id ?? ''
  );
  const setMode = (mode: 'select' | 'manual') =>
    setBucketSelectionState((state) => ({
      ...state,
      restoreMode: mode,
    }));

  const setRestoreBucketName = (value: string) => {
    setBucketSelectionState((state) => ({
      ...state,
      S3: { ...state.S3, Bucket: value },
    }));
  };

  const setRestoreBucketNameOverride = (value: string) => {
    setBucketSelectionState((state) => ({
      ...state,
      S3: { ...state.S3, BucketOverride: value },
    }));
  };

  const setRestorePrefix = (value: string) => {
    setBucketSelectionState((state) => ({
      ...state,
      prefix: value,
    }));
  };

  return (
    <Box className='mx-[40px] my-[24px]'>
      <Box className='flex justify-between items-center'>
        <Typography variant='body1'>Choose a bucket</Typography>
      </Box>
      <List className='mt-[20px]'>
        <OptionListItem
          className='pl-[24px]'
          isChecked={isSelectMode}
          onClick={() => {
            setMode('select');
          }}
        >
          <FormControlLabel
            checked={isSelectMode}
            onClick={() => {
              setMode('select');
            }}
            control={<Radio className='pr-[12px]' />}
            label='Select a bucket'
          />
          <Collapse className='w-full pl-[36px] pr-[40px]' in={isSelectMode}>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              {!s3BucketsResponse ? (
                <EmptySelect title='Bucket*' />
              ) : (
                <FormControl size='small' className='flex-1'>
                  <FormLabel>Bucket*</FormLabel>
                  <Select
                    displayEmpty
                    renderValue={(value: string) => {
                      if (!value) {
                        return <SelectPlaceholder />;
                      }
                      const bucket = s3BucketsResponse?.buckets?.find(
                        (x) => x.bucketName === value
                      );
                      if (!bucket) {
                        return <SelectPlaceholder />;
                      }
                      return <BucketRegionItem {...bucket} />;
                    }}
                    className='h-[58px]'
                    size='small'
                    value={
                      s3BucketsResponse?.buckets?.find(
                        (x) => x.bucketName === S3.Bucket
                      )?.bucketName || ''
                    }
                    onChange={(event) =>
                      setRestoreBucketName(event.target.value)
                    }
                  >
                    {(s3BucketsResponse?.buckets || []).map((bucket) => (
                      <MenuItem
                        key={bucket.bucketName}
                        value={bucket.bucketName}
                      >
                        <BucketRegionItem {...bucket} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <BucketPrefix prefix={prefix} onChange={setRestorePrefix} />
            </Stack>
          </Collapse>
        </OptionListItem>
        <OptionListItem
          className='pl-[24px]'
          isChecked={isManualMode}
          onClick={() => setMode('manual')}
        >
          <FormControlLabel
            checked={isManualMode}
            onClick={() => setMode('manual')}
            control={<Radio className='pr-[12px]' />}
            label='Enter a bucket name'
          />
          <Collapse className='w-full pl-[36px] pr-[40px]' in={isManualMode}>
            <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
              <FormControl size='small' className='w-1/2'>
                <FormLabel>Bucket name*</FormLabel>
                <TransactionalTextField
                  initValue={S3.BucketOverride || ''}
                  onChange={setRestoreBucketNameOverride}
                />
              </FormControl>
              <BucketPrefix prefix={prefix} onChange={setRestorePrefix} />
            </Stack>
          </Collapse>
        </OptionListItem>
      </List>
    </Box>
  );
};

const BucketRegionItem = (props: {
  bucketName: string;
  regionName: string;
}) => {
  return (
    <Stack direction={'column'} className='py-[4px]'>
      <Typography>{props.bucketName}</Typography>
      <Typography variant='body2' className='mt-[4px]'>
        {props.regionName}
      </Typography>
    </Stack>
  );
};

const BucketPrefix = (props: {
  prefix?: string;
  onChange: (value: string) => void;
}) => {
  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>Prefix (optional)</FormLabel>
      <Stack height='100%' direction='column' justifyContent='space-between'>
        <TransactionalTextField
          initValue={props.prefix || ''}
          onChange={props.onChange}
        />
        <Typography paddingTop='8px' variant='body2'>
          Example: /Project/WordFiles/
        </Typography>
      </Stack>
    </FormControl>
  );
};

export const getS3BucketName = (bucket?: RestoreGenericStorageProps) => {
  if (!bucket) {
    return;
  }

  return bucket.restoreMode === 'select'
    ? bucket.S3.Bucket
    : bucket.S3.BucketOverride;
};
