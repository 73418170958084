import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  MenuItem,
  Radio,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import SelectPlaceholder from '../selectPlaceholder';
import { OptionListItem } from '../shared/optionListItem';
import { TransactionalTextField } from '../shared/transactionalTextField';

export const EncryptionComponent = ({
  encryption = {
    isEncrypted: true,
    encryptionKeyId: undefined,
    arn: undefined,
    mode: 'select',
  },
  accountId,
  onChange,
  regionName,
  canBeTurnedOff = false,
  title = 'Encrypt this resource*',
}: {
  encryption?: Encryption;
  accountId: string;
  regionName: string;
  onChange: (encryption: Encryption) => void;
  canBeTurnedOff?: boolean;
  title?: string;
}) => {
  const dal = useDAL();
  const { isLoading, body } = dal.restore.encryptionKeys.list(
    accountId,
    regionName
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (
      encryption.encryptionKeyId &&
      !body?.keys.find((key) => key.arn === encryption.encryptionKeyId)
    ) {
      onChange({ ...encryption, encryptionKeyId: undefined });
    }
  }, [body?.keys, encryption, encryption.encryptionKeyId, isLoading, onChange]);

  const setMode = (mode: 'arn' | 'select') => {
    onChange({ ...encryption, mode });
  };

  const isSelectMode = encryption.mode === 'select';
  const isArnMode = encryption.mode === 'arn';

  return (
    <Box className='mx-[40px] my-[24px]'>
      <Box className='flex justify-between items-center'>
        <Typography className='font-semibold' variant='body1'>
          {title}
        </Typography>
        {canBeTurnedOff && (
          <Switch
            size='small'
            checked={encryption.isEncrypted}
            onChange={() => {
              const isEncrypted = !encryption.isEncrypted;
              onChange({
                ...encryption,
                isEncrypted,
              });
            }}
          />
        )}
      </Box>
      {encryption.isEncrypted && (
        <List className='mt-[20px]'>
          <OptionListItem
            isChecked={isSelectMode}
            onClick={() => {
              setMode('select');
            }}
          >
            <FormControlLabel
              checked={isSelectMode}
              onClick={() => {
                setMode('select');
              }}
              control={<Radio />}
              label='Select an existing key'
            />
            <Collapse className='w-full' in={isSelectMode}>
              <Box>
                <FormControl size='small' className='w-full mt-[20px]'>
                  <FormLabel>Encryption key*</FormLabel>
                  <Box className='flex items-center gap-[36px]'>
                    <Box className='w-1/2'>
                      <Select
                        displayEmpty
                        renderValue={(value: string) => {
                          if (!value) {
                            return <SelectPlaceholder />;
                          }
                          return body?.keys.find((key) => key.arn === value)
                            ?.aliasName;
                        }}
                        disabled={!body || isLoading}
                        size='small'
                        className='w-full'
                        value={encryption.encryptionKeyId || ''}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(event) => {
                          const newState = {
                            ...encryption,
                            encryptionKeyId: event.target.value,
                          };
                          event.stopPropagation();
                          onChange(newState);
                        }}
                      >
                        {body?.keys.map((key) => (
                          <MenuItem key={key.id} value={key.arn}>
                            {key.aliasName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                </FormControl>
              </Box>
            </Collapse>
          </OptionListItem>
          <OptionListItem isChecked={isArnMode} onClick={() => setMode('arn')}>
            <FormControlLabel
              checked={isArnMode}
              onClick={() => setMode('arn')}
              control={<Radio />}
              label='Enter a key ARN'
            />
            <Collapse className='w-full' in={isArnMode}>
              <Box>
                <FormControl size='small' className='w-1/2 mt-[24px]'>
                  <FormLabel>ARN*</FormLabel>
                  <TransactionalTextField
                    initValue={encryption.arn || ''}
                    onChange={(value) => {
                      const newState = {
                        ...encryption,
                        arn: value,
                      };
                      onChange(newState);
                    }}
                  />
                </FormControl>
              </Box>
            </Collapse>
          </OptionListItem>
        </List>
      )}
    </Box>
  );
};

export const getEncryptionKey = (
  encryption?: Encryption
): string | undefined => {
  if (!encryption) {
    return;
  }

  return encryption.mode === 'select'
    ? encryption.encryptionKeyId
    : encryption.arn;
};

export interface Encryption {
  isEncrypted: boolean;
  encryptionKeyId: string | undefined;
  arn: string | undefined;
  mode: 'arn' | 'select';
}
