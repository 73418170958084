'use client';

import {
  LDProvider,
  reactSdkContextFactory,
} from 'launchdarkly-react-client-sdk';
import { type PropsWithChildren } from 'react';

import { useUser } from './useUser';

interface LaunchDarklyProviderProps extends PropsWithChildren {
  clientId?: string;
}

export const flagsContext = reactSdkContextFactory();

export const LaunchDarklyProvider = (props: LaunchDarklyProviderProps) => {
  const { currentProjectId } = useUser();
  if (!currentProjectId) {
    return props.children;
  }

  if (!props.clientId) {
    return (
      <flagsContext.Provider
        value={{
          flags: {
            dataAccessRoles: true,
            assignBackupPolicyToSingleResource: true,
            fullInstanceRestore: {
              filterType: 'exclude',
              providerResourceIds: [],
            },
            enableApiCredentials: true,
            enableNotifications: true,
            enableDbExplorerPopper: true,
            createNewProject: true,
            convertImage: true,
          },
          flagKeyMap: {},
          ldClient: undefined,
        }}
      >
        {props.children}
      </flagsContext.Provider>
    );
  }

  return (
    <LDProvider
      reactOptions={{
        reactContext: flagsContext,
      }}
      clientSideID={props.clientId}
      context={{
        key: currentProjectId || '',
      }}
      options={
        {
          /* ... */
        }
      }
    >
      {props.children}
    </LDProvider>
  );
};
