import { Box, styled, Tooltip } from '@mui/material';

import BackupVaultCard from '@/components/vaults/BackupVaultCard';
import type { BackupVault } from '@/data/vaults/backupVault';
import useBackupVaults from '@/data/vaults/useBackupVaults';

const Tag = styled('div')(
  (props: { backgroundColor: string; additionalStyles?: object }) => ({
    cursor: 'pointer',
    borderRadius: '4px',
    fontSize: '12px',
    padding: '2px 8px',
    margin: '2px 4px',
    color: 'black',
    backgroundColor: props.backgroundColor,
    display: 'inline-block',
    ...props.additionalStyles,
  })
);

function VaultTagFromId(props: { vaultId: string }) {
  const { data } = useBackupVaults();
  const vault = data.find((vault) => vault.id === props.vaultId);
  if (!vault) {
    return null;
  }

  return <VaultTag vault={vault} />;
}

interface VaultTagProps {
  sx?: object;
  vault: BackupVault | string;
}

export default function VaultTag(props: VaultTagProps) {
  if (typeof props.vault === 'string') {
    return <VaultTagFromId vaultId={props.vault} />;
  }

  if (!props.vault) {
    return null;
  }

  return (
    <Tooltip
      disableInteractive={false}
      slotProps={{
        tooltip: {
          sx: {
            padding: 0,
            backgroundColor: 'transparent',
          },
        },
      }}
      title={<BackupVaultCard data={props.vault} editable={false} />}
    >
      <Tag
        data-testid='vault-tag'
        additionalStyles={props.sx}
        backgroundColor={props.vault.backgroundColor || 'black'}
      >
        <Box component={'span'} className='font-semibold mr-[4px]'>
          {props.vault.cloudProvider}
        </Box>
        <Box component={'span'}>{props.vault.name}</Box>
      </Tag>
    </Tooltip>
  );
}
