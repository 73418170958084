import { Stack } from '@mui/material';
import type { InventoryResource, Snapshot } from '@repo/api-gw-sdk';

import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotActionsGroup } from './snapshotActionsGroup';
import { SnapshotDetailsHeader } from './snapshotDetailsHeader';

export const SnapshotRow = ({
  snap,
  vault,
  isSelected,
  onSelect,
  onRestore,
  onConvertSnapshot, // todo: remove when merging fullInstanceRestore
  volume,
  resource,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelected?: boolean | undefined;
  onSelect?: () => void;
  onRestore: () => void;
  onConvertSnapshot?: () => void;
  volume?: string;
  resource: InventoryResource;
}) => {
  return (
    <Stack
      borderTop='1px solid var(--mui-palette-divider)'
      className='py-[24px] px-[40px] w-full'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      key={snap.id}
    >
      <SnapshotDetailsHeader
        snap={snap}
        vault={vault}
        onSelect={onSelect}
        isSelected={isSelected}
        volume={volume}
      />
      <SnapshotActionsGroup
        snapshot={snap}
        vault={vault}
        isSelected={!onSelect || isSelected}
        volume={volume}
        onRestore={onRestore}
        convertSnapshot={
          onConvertSnapshot
            ? { onConvertSnapshot, title: 'Convert to EBS Snapshot' }
            : undefined
        }
        resource={resource}
      />
    </Stack>
  );
};
