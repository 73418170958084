import type { InventoryResource } from '@repo/api-gw-sdk';

import {
  apps,
  accountId,
  backupPolicies,
  backupRegions,
  backupStorageSize,
  dataClasses,
  environment,
  resourceId,
  resourceName,
  backupStatus,
  resourceType,
  sourceRegion,
  sourceStorageSize,
  subnets,
  tags,
  vpc,
  vaults,
  id,
  genericSnapshotsCount,
  dateDiscovered,
  createdTime,
  resourceGroupName,
} from '@/components/queryBuilder/properties';
import { createColumns } from '@/components/queryBuilder/propertiesUtils';

const columns = [
  id,
  backupStatus,
  resourceName,
  resourceType,
  resourceId,
  resourceGroupName,
  tags,

  dataClasses,
  environment,
  apps,

  backupPolicies,
  vaults,
  backupRegions,
  sourceStorageSize,
  backupStorageSize,
  genericSnapshotsCount,
  dateDiscovered,

  accountId,
  sourceRegion,
  vpc,
  subnets,
  createdTime,
];

export const getColumns = () => createColumns<InventoryResource>(columns);
