import { Button, Stack, type ButtonOwnProps } from '@mui/material';
import type { PropsWithChildren } from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  variant?: ButtonOwnProps['variant'];
}

export interface SidePanelFooterProps {
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
}

export const SidePanelFooter = (
  props: PropsWithChildren<SidePanelFooterProps>
) => {
  const { primaryButton, secondaryButton, children } = props;

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      paddingX='40px'
      paddingY='16px'
      className='rounded-b-[16px]'
      sx={{ boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)' }}
    >
      <div>{children}</div>
      <div>
        {secondaryButton && (
          <Button
            size='small'
            variant={secondaryButton.variant || 'outlined'}
            onClick={secondaryButton.onClick}
            className='mr-[12px]'
            data-testid='wizard-step-back-btn'
          >
            {secondaryButton.text}
          </Button>
        )}
        <Button
          size='small'
          variant={primaryButton.variant || 'contained'}
          disabled={primaryButton.disabled}
          onClick={primaryButton.onClick}
          data-testid='wizard-step-next-btn'
        >
          {primaryButton.text}
        </Button>
      </div>
    </Stack>
  );
};
