import type { ApiCredentialsProperties } from '@repo/api-gw-sdk';

import { Wizard } from '@/components/wizard/Wizard';

import {
  UpsertApiCredentialsFlow,
  type UpsertApiCredentialsState,
} from './upsertApiCredentialsFlow';

export interface UpsertApiCredentialsPanelProps {
  entity: ApiCredentialsProperties;
  onSave: (apiCredentials: ApiCredentialsProperties) => void;
  onAbort: () => void;
}

export function ApiCredentialsPanel(props: UpsertApiCredentialsPanelProps) {
  const save = (state: UpsertApiCredentialsState) => {
    props.onSave(state);
    return true;
  };

  const validate = (state: UpsertApiCredentialsState) => {
    if (
      !state.givenName ||
      state.givenName.trim() === '' ||
      state.givenName.length < 4
    ) {
      return 'Name is required';
    }
  };

  return (
    <Wizard<UpsertApiCredentialsState>
      onAbort={props.onAbort}
      onFinish={save}
      validate={validate}
      flow={[UpsertApiCredentialsFlow]}
      initialState={{
        ...props.entity,
      }}
      title={props.entity.clientId ? `Edit Credentials` : `Create Credentials`}
    />
  );
}
