import { useState } from 'react';

import InfoSnackbar from '@/components/infoSnackbar';
import { InstancePanel } from '@/components/panels/instancePanel';
import { useFields } from '@/components/table/hooks';
import { useDAL } from '@/data/dal';

import ExcludeFromBackup from './excludeFromBackup';
import InventoryBulkPolicyAssignmentDialog from './inventoryBulkPolicyAssignmentDialog';
import useInstanceActions from './inventoryInstanceActions';
import { inventoryItemFields } from './inventoryItemFields';

export const InventoryInstancePanel = ({
  id,
  onClose,
  onRowContentChange,
}: {
  id: string;
  onClose: () => void;
  onRowContentChange?: () => Promise<void>;
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [assignBackupPolicyDialogOpen, setAssignBackupPolicyDialogOpen] =
    useState(false);
  const [excludeFromBackupDialogOpen, setExcludeFromBackupDialogOpen] =
    useState(false);
  const dal = useDAL();

  const { isLoading, body, mutate } = dal.inventory.get(id);

  const onEntityChange = async () => {
    await onRowContentChange?.();
    await mutate();
  };

  const fields = useFields(inventoryItemFields);
  const actions = useInstanceActions(
    body?.resource,
    onEntityChange,
    setSnackbarOpen,
    setAssignBackupPolicyDialogOpen,
    setExcludeFromBackupDialogOpen
  );

  return (
    <>
      <InstancePanel
        entity={body?.resource}
        resourceId={body?.resource.id || ''}
        onClose={onClose}
        fields={fields}
        actions={actions}
        isLoading={isLoading}
        openSections={{
          Properties: false,
          Classifications: false,
          SourceLocationFields: false,
          BackupDetailsFields: false,
        }}
        onEntityChange={onEntityChange}
      />
      <InfoSnackbar
        message={'Backup job started.'}
        isOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
      {assignBackupPolicyDialogOpen && (
        <InventoryBulkPolicyAssignmentDialog
          resourceIds={body?.resource.id ? [body?.resource.id] : []}
          onClose={() => {
            setAssignBackupPolicyDialogOpen(false);
            void onEntityChange();
          }}
        />
      )}
      {excludeFromBackupDialogOpen && (
        <ExcludeFromBackup
          isOpen={excludeFromBackupDialogOpen}
          entities={body?.resource ? [body?.resource] : []}
          onClose={() => {
            setExcludeFromBackupDialogOpen(false);
            void onEntityChange();
          }}
        />
      )}
    </>
  );
};
